import { Button, ButtonBase, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, Redirect } from 'react-router-dom';
import wedash from '../../assets/img/logo.png';
import { InputCheckbox, ModalSkeleton } from '../../components';
import { useSnackbar } from '../../providers/snackbar';
import { getUserId } from '../../services/auth';
import useAPI from '../../services/useApi';
import { patchEditUser } from '../../services/user';
import theme from '../../styles/theme';
import * as S from './styled';

export function Onboarding({ location }: any) {
  const callAPI = useAPI();
  const { showSnackbar } = useSnackbar();
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
  const [isOnboardingRedirect, setIsOnboardingRedirect] = useState(false);
  const history = useHistory();

  const handleTermsModalOpen = () => setTermsModalOpen(true);
  const handleTermsModalClose = () => setTermsModalOpen(false);

  const {
    control,
    watch,
    formState: { errors },
  } = useForm();
  const isChecked = watch('termsOfConsent');

  const handleNext = useCallback(async (path: string) => {
    const userId = getUserId();

    const formatedPath: string = location?.state?.from?.pathname || path;

    try {
      await callAPI(patchEditUser, {
        bodyParams: { acceptedTermsAt: new Date() },
        id: userId,
      });

      if (path === '/onboarding') {
        setIsOnboardingRedirect(true);
        return null;
      }

      return history.push(formatedPath);
    } catch (e) {
      showSnackbar({
        type: 'error',
        message: 'Erro ao registrar o aceite dos termos, tente novemnte',
      });
      return null;
    }
  }, []);

  if (isOnboardingRedirect) {
    return (
      <Redirect
        to={{
          pathname: '/onboarding',
          state: { ...location.state },
        }}
      />
    );
  }

  return (
    <>
      <ModalSkeleton
        onCancel={handleTermsModalClose}
        withoutFooter
        opened={termsModalOpen}
        title="Termos de uso"
      >
        <S.IframeWrapper>
          <S.IframeObject
            data="https://wedash-prod.s3.amazonaws.com/terms-of-consent/wedash-terms-of-consent.pdf"
            type="application/pdf"
          >
            <S.Iframe
              title="terms-of-consent"
              src="https://wedash-prod.s3.amazonaws.com/terms-of-consent/wedash-terms-of-consent.pdf"
            />
          </S.IframeObject>
        </S.IframeWrapper>
      </ModalSkeleton>
      <S.Container>
        <S.Card>
          <S.CardTitle variant="h4">Olá! Bem-vindo ao</S.CardTitle>
          <S.LogoWrapper>
            <S.Logo src={wedash} />
          </S.LogoWrapper>
          <S.PostLogoWrapper>
            <S.DashboardDescription>
              Para o seu primeiro acesso iremos apresentar um breve tutorial com
              as principais funcionalidades do seu <strong>dashboard</strong>.
            </S.DashboardDescription>
          </S.PostLogoWrapper>
          <S.TermsOfConsentWrapper>
            <InputCheckbox
              control={control}
              error={errors?.termsOfConsent?.message}
              name="termsOfConsent"
              label={
                <>
                  Aceito os{' '}
                  <ButtonBase type="button" onClick={handleTermsModalOpen}>
                    <Typography
                      style={{
                        fontSize: '1.6rem',
                        color: theme.colors.primary,
                        textDecoration: 'underline',
                      }}
                    >
                      termos de uso
                    </Typography>
                  </ButtonBase>
                </>
              }
            />
          </S.TermsOfConsentWrapper>
          <S.ButtonsWrapper>
            <S.GrayButton
              disabled={!isChecked}
              variant="outlined"
              color="primary"
              onClick={() => handleNext(`/welcome`)}
            >
              Pular tutorial
            </S.GrayButton>
            <Button
              disabled={!isChecked}
              onClick={() => handleNext('/onboarding')}
              variant="contained"
              color="primary"
            >
              Iniciar
            </Button>
          </S.ButtonsWrapper>
        </S.Card>
        <S.Card>
          <S.CardTitle>Dúvidas frequentes</S.CardTitle>
          <S.FaqDescription>
            Surgiu alguma dúvida? Não se preocupe! Aqui temos a nossa FAQ com
            tudo que você pode precisar, incluindo contato com o nosso time de
            especialistas caso não encontre a resposta que você procura.
          </S.FaqDescription>
          <S.ButtonsWrapper>
            <Button
              onClick={() => history.push(`/faq`)}
              variant="outlined"
              color="primary"
            >
              Acesse nosso FAQ
            </Button>
          </S.ButtonsWrapper>
        </S.Card>
      </S.Container>
    </>
  );
}
