import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { createGlobalStyle, css } from 'styled-components';

const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(''),
       url('../public/fonts/roboto-v27-latin-300.woff2') format('woff2'), 
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('../public/fonts/roboto-v27-latin-regular.woff2') format('woff2'), 
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
       url('../public/fonts/roboto-v27-latin-500.woff2') format('woff2'), 
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local(''),
       url('../public/fonts/roboto-v27-latin-900.woff2') format('woff2'), 
}

* {
  margin: 0;
  padding: 0;
}

  ${({ theme }) => css`
    html {
      font-size: 62.5%;
    }
    body {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.medium};
      margin: 0;
      padding: 0;
    }
  `}
`;

export default GlobalStyles;
