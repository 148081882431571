import React, { useCallback, useEffect, useRef, useState } from 'react';
import AnimationLoading from '../../assets/lottie/loading.json';
import { LottieSkeleton, Menu } from '../../components';
import { getUserId } from '../../services/auth';
import { ClientData, getClientByDashId } from '../../services/client';
import { DashData, getDashById } from '../../services/dash';
import useAPI from '../../services/useApi';
import { getUserById, UserData } from '../../services/user';
import * as S from './styled';

type DashProps = {
  dashId: string;
};

const Dash = ({ dashId }: DashProps) => {
  const [dash, setDash] = useState('');
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef(null);
  const [userData, setUserData] = useState<UserData>();
  const [clientData, setClientData] = useState<ClientData>();
  const [dashData, setDashData] = useState<DashData>();
  const callApi = useAPI();

  const renderLoading = () => (
    <div style={{ height: '100vh' }}>
      <LottieSkeleton animation={AnimationLoading} />
    </div>
  );

  const getUserData = useCallback(async () => {
    const userId = getUserId();
    const response = await callApi(getUserById, userId);

    if (response) setUserData(response);
  }, []);

  const getClientData = useCallback(async () => {
    const response = await callApi(getClientByDashId, { dashId });

    if (response) setClientData(response);
  }, [dashId]);

  const getDashData = useCallback(async () => {
    const response = await callApi(getDashById, { id: dashId });

    if (response) setDashData(response);
  }, [dashId]);

  useEffect(() => {
    getUserData();
    getClientData();
    getDashData();
  }, [dashId]);

  const getDash = useCallback(async () => {
    try {
      setLoading(true);
      const dashResponse = await callApi(getDashById, { id: dashId });

      setDash(dashResponse.url);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [callApi, dashId]);

  useEffect(() => {
    getDash();
  }, [getDash, dashId]);

  if (loading) return renderLoading();

  return (
    <>
      <Menu
        userData={userData}
        dashData={dashData}
        clientData={clientData}
        dashId={dashId}
      />
      <S.CustomIframe
        ref={iframeRef}
        title="Dash"
        style={{
          position: 'fixed',
          top: 80,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: 'calc(100% - 4.5rem',
        }}
        src={dash}
      />
    </>
  );
};

export default Dash;
