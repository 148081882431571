import { Button, Modal as MaterialModal } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Modal = styled(MaterialModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalWrapper = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 56rem;
  display: flex;
  background-color: #ffffff;
  padding: 3.4rem 3.2rem;
  outline: none;
  position: relative;
  border-radius: 1rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
`;

export const CloseIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FooterButton = styled(Button)`
  &.MuiButtonBase-root {
    margin-left: 1.5rem;
    text-transform: none;
    height: 4.5rem;
    padding: 0 3rem;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.MuiButton-outlined {
    color: blue;
    border-color: blue;
  }
`;

export const CardsWrapper = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 1440px) {
    height: 170px;
  }
`;
