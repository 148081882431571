import React from 'react';
import ModalSkeleton from '../ModalSkeleton';

type ModalDeleteGoalProps = {
  buttonText: string;
  title: string;
  opened: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

const ModalDeleteGoal = ({
  buttonText,
  title,
  opened,
  onSubmit,
  onCancel,
}: ModalDeleteGoalProps) => (
  <ModalSkeleton
    buttonText={buttonText}
    onDelete={onSubmit}
    title={title}
    opened={opened}
    onCancel={onCancel}
    isErrorButton
  >
    <>
      <p style={{ marginBottom: '1rem' }}>
        Sua meta será excluída definitivamente.
      </p>
      <p>Gostaria realmente de excluir essa meta?</p>
    </>
  </ModalSkeleton>
);

export default ModalDeleteGoal;
