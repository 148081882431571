import { Box as MaterialBox } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Box = styled(MaterialBox)`
  ${({ theme }) => css`
    > img {
      padding: 20px;
      width: 250px;
    }
    p {
      margin-bottom: 0.3rem;
      padding: 0 10px;
      font-size: 20px;
      font-weight: 600;
      color: ${theme.colors.primary};
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    > button:first-child {
      margin-left: ${theme.spacings.xxsmall};
      margin-right: ${theme.spacings.xsmall};
    }
  `}
`;
