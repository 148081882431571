import { useCallback } from 'react';

async function callApi<T extends Function, K>(apiCall: T, params: K) {
  try {
    const { data } = await apiCall(params || {});
    return data || true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return null;
  }
}

export default function useAPI() {
  const callApiCallback = useCallback(callApi, []);

  return callApiCallback;
}
