import { IconButton, Typography } from '@material-ui/core';
import React, { ReactChild, ReactChildren } from 'react';
import { RiSave3Line as SAVE_ICON } from 'react-icons/ri';
import CLOSE_ICON from '../../assets/icon/closeIcon.svg';
import * as S from './styled';

export type ModalSkeletonProps = {
  opened: boolean;
  title?: string;
  children: ReactChild | ReactChildren;
  buttonText?: string;
  isErrorButton?: boolean;
  onSend?: any;
  onDelete?: any;
  onCancel: any;
  buttonsDisabled?: boolean;
  withoutFooter?: boolean;
};

const ModalSkeleton = ({
  buttonText,
  children,
  onCancel,
  opened,
  buttonsDisabled = false,
  isErrorButton = false,
  onDelete,
  onSend,
  title,
  withoutFooter,
}: ModalSkeletonProps) => {
  const buttonType = (variable: boolean) => {
    if (variable) {
      return (
        <S.FooterButton
          variant="contained"
          disabled={buttonsDisabled}
          type="button"
          color="secondary"
          onClick={onDelete}
        >
          {buttonText}
        </S.FooterButton>
      );
    }
    return (
      <S.FooterButton
        variant="contained"
        disabled={buttonsDisabled}
        type="submit"
        color="primary"
        startIcon={<SAVE_ICON size="2rem" />}
      >
        {buttonText}
      </S.FooterButton>
    );
  };

  return (
    <S.Wrapper>
      <S.Modal open={opened}>
        <S.ModalWrapper onSubmit={onSend}>
          <S.Header>
            <Typography
              variant="h3"
              style={{ fontSize: '2.1rem', fontWeight: 'bold' }}
            >
              {title}
            </Typography>
            <IconButton onClick={onCancel}>
              <S.CloseIcon
                src={CLOSE_ICON}
                alt="x representando botão fechar"
              />
            </IconButton>
          </S.Header>
          <S.Body>{children}</S.Body>
          {!withoutFooter && (
            <S.Footer>
              <S.FooterButton
                variant="outlined"
                onClick={onCancel}
                disabled={buttonsDisabled}
              >
                Cancelar
              </S.FooterButton>
              {buttonType(isErrorButton)}
            </S.Footer>
          )}
        </S.ModalWrapper>
      </S.Modal>
    </S.Wrapper>
  );
};

export default ModalSkeleton;
