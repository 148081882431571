import React, { ReactChildren, ReactChild } from 'react';
import * as S from './styled';

export type LayoutProps = {
  children: ReactChild | ReactChildren;
  side?: 'default' | 'left' | 'right' | 'none';
  background?: 'transparent' | 'mainBg' | 'primary' | 'secondary';
  afterProp?: boolean;
  beforeProp?: boolean;
};

const sideReturnProps = {
  default: {
    paddingLeft: { xs: 1, md: 2, lg: 3 },
    paddingRight: { xs: 1, md: 2, lg: 3 },
  },
  left: {
    paddingLeft: { xs: 1, md: 2, lg: 3 },
  },
  right: {
    paddingRight: { xs: 1, md: 2, lg: 3 },
  },
  none: {},
};

const Layout = ({
  children,
  side = 'default',
  background = 'transparent',
  afterProp = false,
  beforeProp = false,
}: LayoutProps) => (
  <S.Box
    py={{ xs: 1, lg: 3 }}
    {...sideReturnProps[side]}
    background={background}
    afterProp={afterProp}
    beforeProp={beforeProp}
  >
    {children}
  </S.Box>
);

export default Layout;
