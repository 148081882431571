import styled from 'styled-components';
import { ButtonWrapperProps } from '../types/ButtonWrapperProps.type';

const MARGIN_RIGHT = '15%';

export const Wrapper = styled.form``;

export const Session = styled.div`
  margin-top: 1.5rem;
`;

export const InputWrapper = styled.div`
  margin-right: ${MARGIN_RIGHT};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  > * + * {
    padding-left: 3rem;
  }
`;

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  margin-top: 1.5rem;

  div {
    margin-right: ${MARGIN_RIGHT};
  }

  button {
    width: 25rem;
    margin: 0;
  }
`;
