import {
  Box,
  Button,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DEFAULT_DASH_ICON from '../../assets/icon/dashIcon.png';
import DEFAULT_CLIENT_AVATAR from '../../assets/img/defaultImg.jpg';
import EMPTY_STATE from '../../assets/img/emptyState.svg';
import LoadingAnimation from '../../assets/lottie/loading.json';
import { useQuery } from '../../hook/useQuery';
import { useDashboard } from '../../providers/dasboard';
import Card from '../Card';
import LottieSkeleton from '../LottieSkeleton';
import * as S from './styled';

const ACTIVE_CONTROLLER = 1;

type Steps = {
  label: string;
  index: 0 | 1;
};

const steps: Steps[] = [
  {
    label: 'Para acessar suas dashs, por favor selecione a empresa primeiro:',
    index: 0,
  },
  { label: 'E qual dashboard você quer acessar?', index: 1 },
];

type VerticalStepperProps = {
  clientData?: any;
  dashData?: any;
  getDashs?: any;
  loading?: boolean;
};

type HasValue = 'true' | 'false';

const ONBOARDING_LOCAL_STORAGE_KEY = 'ONBOARDING';

const VerticalStepper = ({
  clientData,
  dashData,
  getDashs,
  loading = false,
}: VerticalStepperProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedClient, setSelectedClient] = useState('');
  const [clientList, setClientList] = useState<any[]>([]);
  const [dashList, setDashList] = useState<any[]>([]);
  const redirect = useHistory();
  const dashListRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const query = useQuery();

  const { setClientId, setDashboardId } = useDashboard();

  useEffect(() => {
    setClientList(clientData);
  }, [clientData]);

  useEffect(() => {
    if (dashListRef?.current) {
      dashListRef.current.scrollIntoView();
    }
  }, [dashList]);

  useEffect(() => {
    if (dashList.length === 1) {
      setDashboardId(dashList[0].id);
      setClientId(selectedClient);

      const alreadyLoggedBefore = localStorage.getItem(
        ONBOARDING_LOCAL_STORAGE_KEY,
      );

      if (!alreadyLoggedBefore) {
        localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, 'false');
        redirect.push(`/pre-onboarding`);
      } else {
        redirect.push(`/app/${dashList[0].id}`);
      }
    }
  }, [dashList]);

  const handleNext = async (clientId?: string) => {
    setActiveStep(prevState => prevState + ACTIVE_CONTROLLER);
    const param = clientId || selectedClient;
    await getDashs(param);
  };

  const handleAccess = (dashId: string, clientId: string) => {
    setDashboardId(dashId);
    setClientId(clientId);

    const alreadyLoggedBefore = localStorage.getItem(
      ONBOARDING_LOCAL_STORAGE_KEY,
    );

    if (!alreadyLoggedBefore) {
      localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, 'false');
      redirect.push(`/pre-onboarding`);
    } else {
      redirect.push(`/app/${dashId}`);
    }
  };

  useEffect(() => {
    setDashList(dashData);
  }, [dashData]);

  const activeButtonStep = (step: any, variableId: string) => {
    if (step === 'secondStep') {
      handleAccess(variableId, selectedClient);
    } else {
      handleNext(variableId);
    }
  };

  const fillCompanies =
    !!clientList &&
    clientList?.map(element => {
      const { id, name, avatarUrl } = element;

      return {
        id,
        imgSrc: avatarUrl || DEFAULT_CLIENT_AVATAR,
        imgAlt: `logotipo da empresa ${name}`,
        name,
      };
    });

  const fillDashs =
    !!dashList &&
    dashList?.map(element => {
      const { id, name } = element;
      return {
        id,
        imgSrc: DEFAULT_DASH_ICON,
        imgAlt: 'Icone de um gráfico escalado com uma seta orientando a escala',
        name,
      };
    });

  const hasClient: HasValue = clientList?.length !== 0 ? 'true' : 'false';
  const hasDash: HasValue = dashList?.length !== 0 ? 'true' : 'false';

  const returnClient = {
    true: (
      <Grid container>
        {fillCompanies?.map(client => (
          <Grid item xs={3} md={4} sm={6}>
            <Card
              key={client.id}
              imgSrc={client.imgSrc}
              text={client.name}
              variableId={client.id}
              onClick={() => activeButtonStep('firstStep', client.id)}
              setSelectedValue={setSelectedClient}
            />
          </Grid>
        ))}
      </Grid>
    ),
    false: (
      <S.Box display="flex" alignItems="center">
        <img src={EMPTY_STATE} alt="empty state" />
        <p>Nenhum cliente por aqui!</p>
      </S.Box>
    ),
  };

  const returnDash = {
    true: (
      <div id="dash-list" ref={dashListRef}>
        <Box display="flex" flexWrap="wrap">
          {fillDashs?.map(dash => (
            <Card
              key={dash.id}
              imgSrc={dash.imgSrc}
              text={dash.name}
              onClick={() => activeButtonStep('secondStep', dash.id)}
              variableId={dash.id}
              isDash
            />
          ))}
        </Box>
      </div>
    ),
    false: (
      <S.Box display="flex" alignItems="center">
        <img src={EMPTY_STATE} alt="empty state" />
        <div>
          <p>Seu dashboard ainda está sendo criado.</p>
          <p>
            Entre em contato com a equipe de implantação para mais detalhes.
          </p>
        </div>
      </S.Box>
    ),
  };

  const renderLoading = () => <LottieSkeleton animation={LoadingAnimation} />;

  const variableReturn = {
    0: returnClient[hasClient],
    1: returnDash[hasDash],
  };

  const handleBack = () => {
    setActiveStep(prevState => prevState - ACTIVE_CONTROLLER);
  };

  useEffect(() => {
    const clientId = query.get('clientId');
    if (clientId) handleNext(clientId);
  }, []);

  const stepperBiggerThenZero: HasValue =
    activeStep >= ACTIVE_CONTROLLER ? 'true' : 'false';

  const buttonRenderController = {
    true: (
      <S.ButtonWrapper>
        <Button onClick={handleBack}>Voltar</Button>
      </S.ButtonWrapper>
    ),
    false: <S.ButtonWrapper />,
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map(({ label, index }) => (
        <Step key={`key-${index}`}>
          <StepLabel>{label}</StepLabel>
          <StepContent>
            {loading ? renderLoading() : variableReturn[index]}
            {!loading && (
              <Box py={2}>{buttonRenderController[stepperBiggerThenZero]}</Box>
            )}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default VerticalStepper;
