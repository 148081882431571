/* eslint-disable no-unused-vars */
import { Box, Divider } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import LoadingAnimation from '../../assets/lottie/loading.json';
import { LottieSkeleton, Menu, Tab, TabPanel, Tabs } from '../../components';
import Avatar from '../../components/Avatar';
import { getUserCompany, getUserId } from '../../services/auth';
import { ClientData, getClientByDashId } from '../../services/client';
import { CompanyData, getCompanyById } from '../../services/company';
import { DashData, getDashById } from '../../services/dash';
import useAPI from '../../services/useApi';
import {
  EditUserProps,
  getUserById,
  patchEditUser,
  UserData,
} from '../../services/user';
import * as S from './styled';
import AccountDetails from './tabs/AccountDetails';
import Goals from './tabs/Goals';

const Config = () => {
  const [value, setValue] = useState(0);
  const { dashId } = useParams() as any;
  const [userData, setUserData] = useState<UserData>();
  const [companyData, setCompanyData] = useState<CompanyData>();
  const [clientData, setClientData] = useState<ClientData>();
  const [dashData, setDashData] = useState<DashData>();
  const { colors } = useTheme();

  const callAPI = useAPI();

  const {
    control,
    formState: { errors },
  } = useForm();

  const companyId = getUserCompany();

  const getUserData = useCallback(async () => {
    const userId = getUserId();
    const response = await callAPI(getUserById, userId);

    if (response) setUserData(response);
  }, []);

  const getCompanyData = useCallback(async () => {
    const response = await callAPI(getCompanyById, { companyId });

    if (response) setCompanyData(response);
  }, [companyId]);

  const getClientData = useCallback(async () => {
    const response = await callAPI(getClientByDashId, { dashId });

    if (response) setClientData(response);
  }, [dashId]);

  const getDashData = useCallback(async () => {
    const response = await callAPI(getDashById, { id: dashId });

    if (response) setDashData(response);
  }, [dashId]);

  const changeUserData = useCallback(async ({ email, name }) => {
    const response = await callAPI(patchEditUser, {
      bodyParams: { email, name },
      id: getUserId(),
    });

    if (response) {
      getUserData();
      return true;
    }

    return false;
  }, []);

  useEffect(() => {
    getUserData();
    getCompanyData();
    getClientData();
    getDashData();
  }, [dashId, getCompanyData]);

  const handleChangeAvatar = useCallback(async (args: EditUserProps) => {
    await patchEditUser(args);

    await getUserData();
  }, []);

  if (!userData) {
    return (
      <div style={{ height: '100vh' }}>
        <LottieSkeleton animation={LoadingAnimation} />
      </div>
    );
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box bgcolor={colors.mainBg} minHeight="100vh">
      <Menu
        userData={userData}
        dashData={dashData}
        clientData={clientData}
        dashId={dashId}
        atProfile
        elevation={3}
      />
      <S.Box paddingTop={5}>
        <S.AvatarCard elevation={2}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            py={3}
          >
            <Avatar
              entityId={getUserId() || ''}
              avatarPath={userData.avatarUrl}
              name={userData.name}
              round
              size="150"
              change
              onChange={handleChangeAvatar}
            />
            <S.Divider />
            <S.TextWrapper>
              <p>{userData.name}</p>
              <p>{userData.email}</p>
            </S.TextWrapper>
          </Box>
        </S.AvatarCard>
        <S.Card elevation={2}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Dados da conta" index={0} />
              <Tab label="Metas" index={1} disabled />
              <Tab label="Integrações" index={2} disabled />
            </Tabs>
            <Divider />
            <TabPanel value={value} index={0}>
              <AccountDetails
                changeUserData={changeUserData}
                userData={userData}
                companyData={companyData}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Goals clientData={clientData} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              Item Three
            </TabPanel>
          </Box>
        </S.Card>
      </S.Box>
    </Box>
  );
};

export default Config;
