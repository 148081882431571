import { Box, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { Avatar } from '..';
import * as S from './styled';

type CardProps = {
  isDash?: boolean;
  onClick: any;
  imgSrc: string;
  text: string;
  setSelectedValue?: any;
  variableId: string;
};

export type Styled = {
  dash?: boolean;
};

const Card = ({
  isDash = false,
  onClick,
  imgSrc,
  text,
  setSelectedValue,
  variableId,
}: CardProps) => {
  const clientRef = useRef<HTMLInputElement>(null);
  const dashRef = useRef<HTMLInputElement>(null);

  const click = () => {
    setSelectedValue?.(variableId);
    onClick();
  };

  const isDashReturn = (variable: boolean) => {
    if (variable) {
      return (
        <Box py={1} px={2} display="flex" alignItems="center">
          <Avatar avatarPath={imgSrc} entityId="" name={text} />
          <Box px={2}>
            <Typography variant="h6">{text}</Typography>
          </Box>
          <input type="radio" id="dash" name="dash" ref={dashRef} />
        </Box>
      );
    }
    return (
      <Box py={1} px={2} display="flex" alignItems="center">
        <Avatar avatarPath={imgSrc} entityId="" name={text} />
        <Box px={2}>
          <Typography variant="h6">{text}</Typography>
        </Box>
        <input type="radio" id="client" name="client" ref={clientRef} />
      </Box>
    );
  };

  return (
    <Box m={1}>
      <S.Card elevation={2} onClick={click}>
        {isDashReturn(isDash)}
      </S.Card>
    </Box>
  );
};

export default Card;
