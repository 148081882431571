import { API } from './api';
import { ClientData } from './client';

export const getDashsByClient = async ({ clientId }: any) => {
  const api = await API({ auth: true, refresh: true });

  return api.get(`dash?clientId=${clientId}`);
};

export type DashData = {
  id: string;
  name: string;
  url: string;
  active: boolean;
  client: ClientData;
};

export const getDashById = async ({ id }: any) => {
  const api = await API({ auth: true, refresh: true });

  return api.get<DashData>(`dash/${id}`);
};
