const theme = {
  border: {
    radius: '0.4rem',
  },
  font: {
    family:
      "Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    regular: 400,
    semiBold: 500,
    bold: 900,
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.8rem',
    },
  },
  colors: {
    transparent: 'transparent',
    primary: '#4A45D8',
    secondary: '#858EF3',
    mainBg: '#FAFAFA',
    darkBlue: '#222137',
    aquamarine: '#4BC0C0',
    violet: '#6E6ADF',
    red: '#f5284c',
    white: '#FFFFFF',
    black: '#222137',
    gray: '#ABABAB',
    darkGray: '#757575',
    lightGray: '#DDDDDD',
    shadow: '#00000029',
  },
  typography: {
    none: 'transparent',
    primary: '#222137',
    secondary: '#B5B7B7',
    third: '#CBCCCC',
    fourth: '#FFFFFF',
    fifth: '#777777',
    sixth: '#1E1E30',
    green: '#00CF89',
    red: '#F6607B',
    violet: '#807CE3',
  },
  customColor: {
    violet: {
      a10: '#4A45D81A',
    },
    error: {
      a10: '#F443361A',
    },
  },
  alert: {
    error: '#F44336',
    warning: '#FF9800',
    info: '#2196F3',
    success: '#4CAF50',
  },
  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem',
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
};

export default theme;
