import styled, { css } from 'styled-components';
import { SpinnerProps } from '.';

const spinnerType = {
  button: css`
    width: 2rem;
    height: 2rem;
    border: 0.2rem solid;
  `,
};

export const Spinner = styled.div<SpinnerProps>`
  ${({ theme, type, shapeColor, loadColor }) => css`
    border-radius: 50%;
    ${spinnerType[type!]};
    border-color: ${`${theme.colors[shapeColor!]}1A`};
    border-left-color: ${theme.colors[loadColor!]};
    animation: spin 1s linear infinite;
    margin: 0 !important;

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  `}
`;
