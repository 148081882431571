import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import GearIcon from '../../assets/icon/gears.svg';
import { logout } from '../../services/auth';
import {
  ClientData,
  getClientsByCompany,
  GetClientsByCompanyProps,
} from '../../services/client';
import { CompanyData } from '../../services/company';
import { DashData } from '../../services/dash';
import useAPI from '../../services/useApi';
import Avatar from '../Avatar';
import * as S from './styled';

const defaultImage = '/img/avatarIcon.svg';

type DropDownManageAccountProps = {
  name?: string;
  profilePhoto?: string;
  email?: string;
  company?: CompanyData;
  dash?: DashData;
};

export type ButtonProps = {
  fixedSize?: boolean;
};

const DropDownManageAccount = ({
  name,
  profilePhoto,
  email,
  company,
  dash,
}: DropDownManageAccountProps) => {
  const [open, setOpen] = useState(false);
  const [clients, setClients] = useState<ClientData[]>([]);
  const handleOpen = () => setOpen(prevState => !prevState);
  const history = useHistory();
  const { dashId } = useParams<{ dashId: string }>();

  const callApi = useAPI();

  const handleChangeClient = (clientId: string) =>
    history.push(`/welcome?clientId=${clientId}`);

  const handleNavigateToConfig = useCallback(() => {
    history.push(`/app/${dashId}/config`);
  }, []);

  const getClients = useCallback(async () => {
    if (!company?.id) return;
    const response = await callApi<
      typeof getClientsByCompany,
      GetClientsByCompanyProps
    >(getClientsByCompany, {
      companyId: company?.id,
    });
    setClients(response?.result);
  }, [company?.id]);

  useEffect(() => {
    getClients();
  }, [getClients]);

  return (
    <S.Wrapper open={open}>
      <button type="button" onClick={handleOpen}>
        <Avatar
          size="48"
          round
          avatarPath={profilePhoto}
          entityId="profile-closed"
          name={name || ''}
        />
      </button>
      <S.Drop open={open}>
        <S.InfoUser>
          <Avatar
            size="48"
            round
            avatarPath={profilePhoto}
            entityId="profile-opened"
            name={name || ''}
          />
          <S.Data>
            <span>{name}</span>
            <span>{email}</span>
            <S.Button
              style={{ backgroundColor: 'transparent' }}
              onClick={handleNavigateToConfig}
            >
              <S.GearIcon src={GearIcon} alt="Engrenagem" />
              <S.GearText>Gerenciar minha conta</S.GearText>
            </S.Button>
          </S.Data>
        </S.InfoUser>
        <S.ClientsWrapper>
          <S.ClientsContainer>
            {clients?.map(client => (
              <S.ClientWrapper
                onClick={() => handleChangeClient(client?.id)}
                active={client?.id === dash?.client?.id}
              >
                <Avatar
                  size="48"
                  round
                  avatarPath={client?.avatarUrl}
                  entityId="company-profile"
                  name={client?.name || ''}
                />
                <S.ClientName>{client?.name}</S.ClientName>
              </S.ClientWrapper>
            ))}
          </S.ClientsContainer>
        </S.ClientsWrapper>
        <S.ManagerAccountWrapper>
          <S.LinkWrapper href="https://wedash.digital/faq" target="_blank">
            FAQ
          </S.LinkWrapper>
          <S.LinkWrapper onClick={logout}>Sair da Conta</S.LinkWrapper>
        </S.ManagerAccountWrapper>
        <S.WrapperList>
          <li>
            <a
              href="https://wedash.digital/404"
              target="_blank"
              rel="noreferrer"
            >
              <S.LinkList>Política de Privacidade</S.LinkList>
            </a>
          </li>
          <li>
            <a
              href="https://wedash-prod.s3.amazonaws.com/terms-of-consent/wedash-terms-of-consent.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <S.LinkList>Termos de Serviço</S.LinkList>
            </a>
          </li>
        </S.WrapperList>
      </S.Drop>
      <S.Overlay open={open} onClick={handleOpen} />
    </S.Wrapper>
  );
};

DropDownManageAccount.defaultProps = {
  name: '',
  profilePhoto: defaultImage,
  email: '',
};

export default DropDownManageAccount;
