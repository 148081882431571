/* eslint-disable no-unused-vars */
import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HorizontalStepper, Logo } from '../../../components';
import * as S from './styled';

const onboardingSteps = [
  {
    url: 'h0nIJ7kziaU',
    title: 'Bem-vindo!',
    description:
      'Agora que você já faz parte do time We.Dash. vamos ver como ela funciona na prática? Preparamos um material super legal para que você possa se familiarizar com a plataforma e assim explorar da melhor forma possível.',
  },
];

export type HorizontalStepProps = {
  url?: string;
  title?: string;
  description?: string;
};

const renderStep = ({ url, title, description }: HorizontalStepProps) => (
  <S.Step>
    <iframe
      width="100%"
      height="507"
      src={`https://www.youtube.com/embed/${url}?controls=0&rel=0`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
    <Typography
      style={{
        fontSize: '2.4rem',
        fontWeight: 'bold',
        marginTop: '4rem',
        marginBottom: '1.6rem',
        textAlign: 'center',
      }}
    >
      {title}
    </Typography>
    <Typography
      style={{
        fontSize: '1.6rem',
        maxWidth: '52rem',
        textAlign: 'center',
        margin: '0 auto',
      }}
    >
      {description}
    </Typography>
  </S.Step>
);

const OnboardingSteps = ({ location }: any) => {
  const [steps] = useState(onboardingSteps);
  const history = useHistory();

  const handleNavigate = () => {
    const redirectPath = location?.state?.from?.pathname || '/welcome';

    history.push(redirectPath);
  };

  return (
    <S.Container>
      <S.ContentWrapper>
        <Logo />
        <HorizontalStepper
          leftButtonText="Pular tutorial"
          rightButtonText="Concluir"
          onFinish={handleNavigate}
        >
          {steps.map(step => renderStep(step))}
        </HorizontalStepper>
      </S.ContentWrapper>
    </S.Container>
  );
};

export default OnboardingSteps;
