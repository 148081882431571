import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import MAN_NOTEBOOK from '../../assets/img/manLaptop.svg';
import {
  Button,
  InputPassword,
  InputText,
  Layout,
  Logo,
  Snackbar,
} from '../../components';
import ModalRecoveryPassword from '../../components/ModalRecoveryPassword';
import { useSnackbar } from '../../providers/snackbar';
import { signIn } from '../../services/auth';
import useAPI from '../../services/useApi';
import { postRequestPassword } from '../../services/user';
import LoginSchema from '../../validations/LoginSchema';
import * as S from './styled';

type ErrorMessage = 'Unauthorized' | null | undefined;

const ErrorMapping = {
  Unauthorized: 'Email ou senha inválidos',
};
const ONBOARDING_LOCAL_STORAGE_KEY = 'ONBOARDING';

const Login = ({ location }: any) => {
  const [modalState, setModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isOnboardingRedirect, setIsOnboardingRedirect] = useState(false);
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const openModal = () => setModalState(true);
  const closeModal = () => setModalState(false);
  const callAPI = useAPI();

  const requestRecoveryPassword = useCallback(async (bodyParams, email) => {
    const response = await callAPI(postRequestPassword, bodyParams);

    if (response) {
      showSnackbar({
        type: 'success',
        message: `Foi enviado um e-mail para ${email} com informações para alterar sua senha.`,
      });
    } else {
      showSnackbar({
        type: 'error',
        message: 'Erro interno!',
      });
    }
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const { colors } = useTheme();

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await signIn(data);

      const alreadyLoggedBefore = localStorage.getItem(
        ONBOARDING_LOCAL_STORAGE_KEY,
      );

      if (!alreadyLoggedBefore) {
        localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, 'false');

        setIsOnboardingRedirect(true);
      } else {
        const redirectPath = location?.state?.from?.pathname || '/welcome';

        history.push(redirectPath);
      }
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message as ErrorMessage;

      if (!errorMessage) {
        setError('Erro interno, tente novamente mais tarde');
      } else {
        const err = ErrorMapping[errorMessage];
        setError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  if (isOnboardingRedirect) {
    return (
      <Redirect
        to={{
          pathname: '/pre-onboarding',
          state: { ...location.state },
        }}
      />
    );
  }

  return (
    <>
      <Layout afterProp beforeProp>
        <Box>
          <Box
            py={{ xs: 1, sm: 3 }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <a href="https://wedash.digital/">
              <Logo />
            </a>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={{ xs: 'center', md: 'space-around' }}
          >
            <Box display={{ xs: 'none', md: 'flex' }}>
              <S.ContainerImg src={MAN_NOTEBOOK} alt="" />
            </Box>
            <Box py={{ xs: 2, sm: 3 }}>
              <Card elevation={2}>
                <Box
                  paddingX={{ xs: 2, md: 5 }}
                  display="flex"
                  minHeight={{ md: '350px' }}
                  minWidth={{ md: '400px' }}
                  maxHeight={{ md: '400px' }}
                  maxWidth={{ md: '350px' }}
                >
                  <S.Form onSubmit={handleSubmit(onSubmit)}>
                    <Typography variant="h1" align="center">
                      Bem-vindo!
                    </Typography>
                    <Box py={{ xs: 2, sm: 4 }}>
                      <InputText
                        control={control}
                        error={errors.email?.message}
                        label="E-mail"
                        name="email"
                        style={{ marginBottom: '3rem' }}
                      />
                      <InputPassword
                        control={control}
                        error={errors.password?.message}
                        label="Senha"
                        name="password"
                      />
                    </Box>
                    <Button
                      text="Entrar"
                      color="primary"
                      variant="contained"
                      type="submit"
                      loading={loading}
                      style={{ width: '30rem', alignSelf: 'center' }}
                      rounded
                    />
                    <p
                      style={{
                        textAlign: 'center',
                        marginTop: 16,
                        color: colors.red,
                      }}
                    >
                      {error}
                    </p>
                    <Typography variant="h5" align="center">
                      Novo no <strong>We.Dash</strong>?{' '}
                      <a href="https://wedash.digital/lead-form/">
                        Inscreva-se aqui
                      </a>
                    </Typography>
                    <S.ForgotPassword onClick={openModal}>
                      <p>Esqueceu sua senha?</p>
                    </S.ForgotPassword>
                    <Typography variant="body1" align="center">
                      <S.PrivacyAndTermsLink href="https://api-hml.wedash.digital/aws/terms">
                        Termos de uso
                      </S.PrivacyAndTermsLink>
                      {' - '}
                      <S.PrivacyAndTermsLink href="https://api-hml.wedash.digital/aws/policies">
                        Política de privacidade
                      </S.PrivacyAndTermsLink>
                    </Typography>
                  </S.Form>
                </Box>
              </Card>
            </Box>
          </Box>
        </Box>
      </Layout>
      <ModalRecoveryPassword
        buttonText="Enviar"
        title="Recuperar Senha"
        opened={modalState}
        onSend={requestRecoveryPassword}
        onCancel={closeModal}
      />
      <Snackbar />
    </>
  );
};

export default Login;
