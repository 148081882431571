import CustomAvatar from 'react-avatar';
import styled from 'styled-components';
import { CustomAvatarProps } from '.';

export const Wrapper = styled.div`
  cursor: default;
  user-select: none;
`;

export const Upload = styled.input`
  display: none;
`;

export const Avatar = styled(CustomAvatar)<CustomAvatarProps>`
  object-fit: contain;
`;
