import { Checkbox as MUICheckbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import * as S from '../styled';
import { CheckboxProps } from '../types/checkbox.type';

const Checkbox = ({ name, control, label, error, title }: CheckboxProps) => (
  <S.InputWrapper title={title}>
    {title && <S.Title>{title}</S.Title>}
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel
          control={<MUICheckbox {...field} color="primary" />}
          label={label}
        />
      )}
    />
    {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
  </S.InputWrapper>
);

export default Checkbox;
