import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 90rem;

  > div > svg {
    margin: 6rem 0 4rem 0;
  }

  padding-bottom: 4rem;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
