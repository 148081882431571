import styled, { css } from 'styled-components';

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    > h1 {
      font-weight: ${theme.font.semiBold};
    }

    a {
      color: ${theme.colors.violet};
      font-weight: ${theme.font.semiBold};
      text-decoration: none;
    }

    a:hover {
      color: ${theme.colors.violet};
      text-decoration: none;
      transform: none;
      cursor: pointer;
    }
  `}
`;

export const ContainerImg = styled.img`
  height: 550px;
`;

export const Img = styled.img`
  margin-right: 10px;
`;

export const ForgotPassword = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    > p {
      padding-top: 0.625rem;
      font-size: 15px;
      color: ${theme.colors.violet};
      font-weight: ${theme.font.semiBold};
      text-align: center;
    }
  `}
`;

export const PrivacyAndTermsLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.darkBlue} !important;
    font-size: 1rem;
    text-decoration: none;
  `}
`;
