import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect
} from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import * as S from '../styled';
import { SelectProps } from '../types/select.type';

const Select = ({
  name,
  control,
  label,
  error,
  title,
  options,
}: SelectProps) => (
  <S.InputWrapper title={title}>
    {title && <S.Title>{title}</S.Title>}
    <FormControl variant="outlined">
      <InputLabel htmlFor={`outlined-${label}-native-simple`}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MUISelect
            {...field}
            label={label}
            inputProps={{
              name,
              id: `outlined-${label}-native-simple`,
            }}
          >
            {options?.map(option => (
              <MenuItem value={option.value}>{option.name}</MenuItem>
            ))}
          </MUISelect>
        )}
      />
    </FormControl>
    {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
  </S.InputWrapper>
);

export default Select;
