import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .slick-dots {
      list-style: none;
      bottom: -4rem;

      li {
        button {
          display: none;
        }

        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin: 0 1rem;
        background-color: ${theme.colors.secondary}4D;

        &.slick-active {
          background-color: ${theme.colors.secondary};
        }
      }
    }
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 30rem;
  margin: 8rem auto;

  button:last-child {
    margin-left: 1.6rem;
  }
`;
