import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import * as S from '../styled';
import { PasswordProps } from '../types/password.type';

const Password = ({
  name,
  control,
  label,
  error,
  title,
  disabled,
}: PasswordProps) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const changePasswordVisibility = () => {
    setPasswordVisibility(prevState => !prevState);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <S.InputWrapper title={title}>
      {title && <S.Title>{title}</S.Title>}
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <OutlinedInput
              {...field}
              id="outlined-adornment-password"
              disabled={disabled}
              type={passwordVisibility ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={changePasswordVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    disabled={disabled}
                  >
                    {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          )}
        />
      </FormControl>
      {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
    </S.InputWrapper>
  );
};

export default Password;
