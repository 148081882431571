import { Box } from '@material-ui/core';
import React from 'react';

type TabPanelProps = {
  children?: React.ReactNode;
  index: any;
  value: any;
};

const TabPanel = ({ children, index, value, ...props }: TabPanelProps) => (
  <div
  role="tabpanel"
  hidden={value !== index}
  id={`simple-tabpanel-${index}`}
  aria-labelledby={`simple-tab-${index}`}
  {...props}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

export default TabPanel;
