import React from 'react';
import { Helmet } from 'react-helmet-async';

type ObjectProps = {
  lang: string;
  title: string;
  image: string;
  url: string;
  author: string;
  description: string;
};

const SEO = ({ author, description, image, lang, title, url }: ObjectProps) => (
  <Helmet
    htmlAttributes={{
      lang,
    }}
    title={title}
    titleTemplate={title}
    meta={[
      {
        name: `ìmage`,
        property: `og:image`,
        content: image,
      },
      {
        name: `ìmage`,
        property: `og:image:secure_url`,
        content: image,
      },
      {
        name: `ìmage`,
        property: `og:image:type`,
        content: `image/png`,
      },
      {
        name: `ìmage`,
        property: `og:image:width`,
        content: `400`,
      },
      {
        name: `ìmage`,
        property: `og:image:height`,
        content: `400`,
      },
      {
        name: `ìmage`,
        property: `og:image:alt`,
        content: `site favIcon`,
      },
      {
        property: `og:url`,
        content: url,
      },
      {
        property: `author`,
        content: author,
      },
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: author,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      },
    ]}
  >
    <title>{title}</title>
  </Helmet>
);

export default SEO;
