import React, { createContext, useContext, useState } from 'react';

type DashboardContextProps = {
  dashboardId: string;
  clientId: string;
  setDashboardId: any;
  setClientId: any;
};

export const DashboardContext = createContext<DashboardContextProps | null>(
  null,
);

const DashboardProvider = ({ children }: any) => {
  const [clientId, setClientId] = useState('');
  const [dashboardId, setDashboardId] = useState<string>('');

  return (
    <DashboardContext.Provider
      value={{
        dashboardId,
        setDashboardId,
        clientId,
        setClientId,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

function useDashboard() {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
}

export { DashboardProvider, useDashboard };
