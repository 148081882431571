import React from 'react';
import { DashboardProvider } from './providers/dasboard';
import { SnackbarProvider } from './providers/snackbar';
import Routes from './routes/routes';

function App() {
  return (
    <SnackbarProvider>
      <DashboardProvider>
        <Routes />
      </DashboardProvider>
    </SnackbarProvider>
  );
}

export default App;
