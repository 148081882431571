/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export type AlertType = 'success' | 'info' | 'warning' | 'error';

type Props = {
  children: React.ReactNode;
};

type ShowSnackbarProps = {
  type?: AlertType;
  message: string;
  duration?: number;
};

type SnackbarContextProps = {
  type: AlertType;
  setType: (type: AlertType) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  duration: number;
  setDuration: (duration: number) => void;
  message: string;
  setMessage: (message: string) => void;
  showSnackbar: (props: ShowSnackbarProps) => void;
};

export const SnackBarContext = createContext<SnackbarContextProps | null>(null);

const SnackbarProvider = ({ children }: Props) => {
  const [type, setType] = useState<AlertType>('success');
  const [isOpen, setIsOpen] = useState(false);
  const [duration, setDuration] = useState(3000);
  const [message, setMessage] = useState('');

  const showSnackbar = useCallback(
    ({ type = 'success', message, duration = 3000 }: ShowSnackbarProps) => {
      setType(type);
      setMessage(message);
      setDuration(duration);
      setIsOpen(true);
    },
    [],
  );

  const resetStates = useCallback(() => {
    setType('success');
    setDuration(3000);
    setMessage('');
  }, []);

  useEffect(() => {
    if (!isOpen) {
      resetStates();
    }
  }, [isOpen, resetStates]);

  return (
    <SnackBarContext.Provider
      value={{
        type,
        setType,
        isOpen,
        setIsOpen,
        duration,
        setDuration,
        message,
        setMessage,
        showSnackbar,
      }}
    >
      {children}
    </SnackBarContext.Provider>
  );
};

function useSnackbar() {
  const context = useContext(SnackBarContext);
  if (!context) {
    throw new Error('useSnackBar must be used within a SnackBarProvider');
  }
  return context;
}

export { SnackbarProvider, useSnackbar };
