import styled, { css } from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Img = styled.img`
  margin-right: 1rem;
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    margin-top: 1rem;
    font-size: 1.2rem;
    text-align: center;
    color: ${theme.colors.red};
  `}
`;
