import { Button, Card as MUICard, darken, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  min-height: 100vh;
`;

export const Card = styled(MUICard)`
  width: 60rem;

  margin: 1rem;
  padding: 4rem;

  ${media.lessThan('huge')`
    padding: 2rem;
  `}
`;

export const CardTitle = styled(Typography)`
  &.MuiTypography-root {
    font-size: 2.4rem;
    font-weight: bold;
  }

  ${media.lessThan('huge')`
  &.MuiTypography-root {
    font-size: 1.8rem;
  }
  `}
`;

export const Logo = styled.img`
  width: 29rem;
  height: 10.5rem;

  ${media.lessThan('huge')`
    width: 15rem;
    height: 5rem;
  `}
`;

export const PostLogoWrapper = styled.div`
  > .MuiTypography-root {
    margin: 3rem 0 0 0;
    font-size: 1.6rem;
  }

  ${media.lessThan('huge')`
    margin: 1.5rem 0 0 0;

    > .MuiTypography-root {
    margin: 1.5rem 0 0 0;
    font-size: 1.4rem;
  }
  `}
`;

export const LogoWrapper = styled.div`
  margin: 2rem 2rem 2rem 0;

  ${media.lessThan('huge')`
    margin: 1rem 1rem 1rem 0;
  `}
`;

export const DashboardDataWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-top: 1rem;

  ${({ theme }) => css`
    > .MuiTypography-root {
      margin: 1rem;

      font-size: 1.6rem;
      color: ${theme.colors.darkGray};

      ${media.lessThan('huge')`
        font-size: 1.2rem;
      `}
    }
  `}
`;

export const DashboardDescription = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.6rem;

    ${media.lessThan('huge')`
      font-size: 1.4rem;
    `}
  }
`;

export const DashboardDescriptionLink = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: underline;

    ${media.lessThan('huge')`
      font-size: 1.4rem;
    `}
  }
`;

export const FaqDescription = styled(Typography)`
  &.MuiTypography-root {
    margin: 1.6rem 0 0 0;
    font-size: 1.6rem;

    ${media.lessThan('huge')`
      font-size: 1.4rem;
    `}
  }
`;

export const TermsOfConsentWrapper = styled.div`
  margin: 3rem 0 2rem 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  > .MuiButton-root {
    margin: 1rem 3rem 0 0;

    height: 4rem;
    padding: 1.2rem 3rem;
    text-transform: none;

    > .MuiButton-label {
      font-size: 1.4rem;
    }
  }
`;

export const GrayButton = styled(Button)`
  &.MuiButton-root {
    ${({ theme }) => css`
      border-color: ${theme.colors.darkGray};
      color: ${theme.colors.darkGray};

      &:hover {
        border-color: ${darken(theme.colors.darkGray, 0.6)};
        color: ${darken(theme.colors.darkGray, 0.6)};
      }
    `}
  }
`;

export const IframeWrapper = styled.div`
  height: 80vh;
`;

export const Iframe = styled.iframe`
  height: 100%;
  width: 100%;
`;

export const IframeObject = styled.object`
  height: 100%;
  width: 100%;
`;
