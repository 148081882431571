import React from 'react';
import * as S from './styled';

export type SpinnerProps = {
  type?: 'button';
  shapeColor?: 'primary' | 'secondary' | 'white';
  loadColor?: 'primary' | 'secondary' | 'white';
};

const Spinner = ({
  type = 'button',
  shapeColor = 'white',
  loadColor = 'primary',
}: SpinnerProps) => (
  <S.Spinner type={type} shapeColor={shapeColor} loadColor={loadColor} />
);

export default Spinner;
