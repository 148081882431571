import React from 'react';
import Lottie from 'react-lottie';

type LottieSkeletonProps = {
  animation: any;
  lottieWidth?: string;
  lottieHeight?: string;
};

const LottieSkeleton = ({
  animation,
  lottieWidth = '20rem',
  lottieHeight = '16rem',
}: LottieSkeletonProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Lottie
        options={defaultOptions}
        height={lottieHeight}
        width={lottieWidth}
      />
    </div>
  );
};

export default LottieSkeleton;
