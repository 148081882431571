import { Card as MaterialCard } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Styled } from './index';

export const Card = styled(MaterialCard)<Styled>`
  ${({ theme }) => css`
    max-width: 600px;
    min-width: 200px;
    cursor: pointer;
    &:hover {
      border-color: ${theme.colors.primary};
      transition: all 0.7s ease-in;
    }
    input {
      display: none;
    }
  `}
`;

export const Img = styled.img<Styled>`
  width: ${({ dash }) => (dash ? '40px' : '70px')};
  border-radius: 10px;
`;
