import { Snackbar as MaterialUiSnackBar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import styled from 'styled-components';
import { TextProps } from '.';

export const MaterialSnackBar = styled(MaterialUiSnackBar)`
  min-width: 300px;
`;

export const CustomAlert = styled(Alert)`
  width: 100%;
`;

export const Text = styled.p<TextProps>`
  color: ${({ textColor, theme }) => theme.alert[textColor]};
  font-size: 1.5rem;
`;
