import styled from 'styled-components';

export const CustomIframe = styled.iframe`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  body .logoBar {
    display: none !important;
  }
`;
