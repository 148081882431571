import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EditGoalProps } from '../../services/goals';
import { GoalItemProps, kpiMap } from '../../templates/Config/tabs/Goals';
import Currency from '../Inputs/Currency';
import DatePicker from '../Inputs/DatePicker';
import Select from '../Inputs/Select';
import Text from '../Inputs/Text';
import ModalSkeleton from '../ModalSkeleton';
import * as S from './styled';

type ModalEditGoalProps = {
  buttonText: string;
  title: string;
  opened: boolean;
  edit: any;
  closeOnSubmit: () => void;
  onCancel: () => void;
  goal?: GoalItemProps | {};
};

const ModalEditGoal = ({
  buttonText,
  title,
  opened,
  edit,
  closeOnSubmit,
  onCancel,
  goal,
}: ModalEditGoalProps) => {
  const {
    reset,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const selectedKPI = watch('kpi');

  useEffect(() => {
    if (goal && 'kpi' in goal) {
      reset({
        kpi: goal.kpi,
        value: goal.value,
        startAt: goal.startAt,
        endAt: goal.endAt,
      });
    }
  }, [goal]);

  const onSubmit = (body: EditGoalProps) => {
    if (goal && 'id' in goal) edit({ id: goal.id, body });
    reset();
    closeOnSubmit();
  };

  return (
    <ModalSkeleton
      buttonText={buttonText}
      onSend={handleSubmit(onSubmit)}
      title={title}
      opened={opened}
      onCancel={() => {
        reset();
        onCancel();
      }}
    >
      <S.Wrapper>
        <Select
          name="kpi"
          error={errors.kpi?.message}
          control={control}
          options={kpiMap}
          title="KPI"
        />
        {kpiMap.find(item => item.value === selectedKPI)?.type === 'number' ? (
          <Text
            name="value"
            error={errors.value?.message}
            control={control}
            title="Valor da meta"
          />
        ) : (
          <Currency
            name="value"
            error={errors.value?.message}
            control={control}
            title="Valor da meta"
          />
        )}
        <DatePicker
          name="startAt"
          error={errors.startAt?.message}
          control={control}
          title="Data início"
        />
        <DatePicker
          name="endAt"
          error={errors.endAt?.message}
          control={control}
          title="Data fim"
        />
      </S.Wrapper>
    </ModalSkeleton>
  );
};

export default ModalEditGoal;
