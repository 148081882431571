import React from 'react';
import * as S from './styled';

type TabsProps = {
  ariaLabel?: string;
  children: React.ReactNode;
  value: any;
  onChange: any;
};

const Tabs = ({
  ariaLabel = 'A control tabs for cliente configuration',
  children,
  value,
  onChange,
  ...props
}: TabsProps) => (
  <S.Tabs
    {...props}
    aria-label={ariaLabel}
    textColor="inherit"
    value={value}
    onChange={onChange}
  >
    {children}
  </S.Tabs>
);

export default Tabs;
