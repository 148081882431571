import { ButtonBaseProps } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { Spinner } from '..';
import * as S from './styled';

export type ButtonProps = {
  text?: string;
  variant?: 'outlined' | 'contained';
  color?: 'primary' | 'secondary';
  type?: 'submit' | 'button' | 'reset';
  fullWidth?: boolean;
  loading?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  onClick?: any;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: CSSProperties;
} & ButtonBaseProps;

function Button({
  text,
  variant = 'contained',
  color = 'primary',
  type = 'submit',
  fullWidth = false,
  loading = false,
  disabled = false,
  rounded = false,
  onClick = () => {},
  startIcon,
  endIcon,
  style,
}: ButtonProps) {
  const buttonRef = useRef(null) as any;
  const [width, setWidth] = useState(0);
  const buttonProps = type === 'submit' ? {} : { onClick };

  useEffect(() => {
    if (buttonRef.current) {
      setWidth(buttonRef?.current?.offsetWidth);
    }
  }, []);

  return (
    <S.Button
      ref={buttonRef}
      width={width}
      rounded={rounded}
      variant={variant}
      color={color}
      type={type}
      fullWidth={fullWidth}
      disabled={disabled || loading}
      startIcon={!loading ? startIcon : null}
      endIcon={!loading ? endIcon : null}
      style={style}
      {...buttonProps}
    >
      {loading ? <Spinner loadColor="white" shapeColor="white" /> : text}
    </S.Button>
  );
}

export default Button;
