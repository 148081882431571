import { GoalItemProps } from '../templates/Config/tabs/Goals';
import { API } from './api';

export type CreateGoalDto = {
  value: string;
  kpi: string;
  startAt: string;
  endAt: string;
  clientId: string;
};

type GetGoalsByIdProps = {
  clientId: string;
};

export const getAllGoals = async ({ clientId }: GetGoalsByIdProps) => {
  const api = await API({ auth: true, refresh: true });

  return api.get(`goal?clientId=${clientId}`);
};

export const saveGoal = async (body: CreateGoalDto) => {
  const api = await API({ auth: true, refresh: true });

  return api.post('goal', body);
};

export type EditGoalProps = {
  body: GoalItemProps;
} & GoalItemProps;

export const editGoal = async ({ id, body }: EditGoalProps) => {
  const api = await API({ auth: true, refresh: true });

  return api.patch(`goal/${id}`, body);
};

export const deleteGoal = async ({ id }: GoalItemProps) => {
  const api = await API({ auth: true, refresh: true });

  return api.delete(`goal/${id}`);
};
