import { Button as MUIButton } from '@material-ui/core';
import styled, { css, DefaultTheme } from 'styled-components';
import { ButtonProps } from '.';
import { Disabled } from '../Menu';
import { HasActive, HasOpen } from './types/base.types';

const dropModifiers = {
  default: () => css`
    transform: scale(0);
  `,
  active: () => css`
    transform: scale(1);
  `,
};

const avatarStyle = {
  default: (theme: DefaultTheme) => css`
    button {
      border: 0.2rem solid ${theme.colors.transparent};
      transition: all 0.35s ease-out;
      outline-offset: 0.2rem;
    }
  `,
  active: (theme: DefaultTheme) => css`
    > div {
      border: 0.2rem solid ${theme.colors.primary};
      transition: all 0.35s ease-in;
      outline-offset: 0.2rem;
    }
  `,
  hover: (theme: DefaultTheme) => css`
    &:hover {
      > div {
        border: 0.2rem solid ${theme.colors.primary};
        transition: all 0.35s ease-in;
        outline-offset: 0.2rem;
      }
    }
  `,
};

const overlayModifiers = {
  default: () => css`
    display: none;
    transition: all 0.35s ease-out;
  `,
  open: () => css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 998;
    transition: all 0.35s ease-in;
  `,
};

export const Wrapper = styled.div<HasOpen>`
  ${({ open, theme }) => css`
    > button {
      outline: none;
      border-radius: 50%;
      border: 0.2rem solid ${theme.colors.transparent};
      cursor: pointer;
      padding: 0.3rem;
      transition: all 0.35s ease-out;
      margin-left: 1rem;
      &:hover {
        border: 0.2rem solid ${theme.colors.primary};
        transition: all 0.35s ease-in;
      }
      ${open && avatarStyle.active(theme)};
      ${!open && avatarStyle.default(theme)};
    }
  `}
`;

export const Drop = styled.div<HasOpen>`
  ${({ theme, open }) =>
    css`
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background-color: ${theme.colors.white};
      width: 35rem;
      height: auto;
      padding: 2rem 1rem;
      border-radius: 1rem;
      margin-left: -29rem;
      margin-top: 1rem;
      box-shadow: 0.3rem 0 0.6rem ${theme.colors.shadow};
      border: 0.1rem solid ${theme.colors.shadow};
      z-index: 999;
      ${!open && dropModifiers.default()};
      ${open && dropModifiers.active()};
    `}
`;

export const WrapperList = styled.ul`
  display: inline-flex;
  padding-top: 2rem;
  li {
    list-style: none;
    font-size: 1.3rem;
    padding: 0 0.5rem;
    a {
      text-decoration: none;
    }
  }
`;

export const InfoUser = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding-bottom: 2rem;
    > span {
      width: auto;
      text-align: left;
      padding: 0;
      font-size: ${theme.font.sizes.small};
      font-weight: ${theme.font.regular};
      opacity: 1;
      color: ${theme.colors.darkGray};
      background-color: ${theme.colors.transparent};
    }
  `}
`;

export const Data = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LinkList = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.typography.fifth};
    &:hover {
      color: ${theme.colors.primary};
    }
  `}
`;

export const ManagerAccountWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 2rem 0;
    border-top: 0.1rem solid ${theme.colors.lightGray}80;
  `}
`;

export const ClientsContainer = styled(ManagerAccountWrapper)`
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`;

export const LinkWrapper = styled.a`
  ${({ theme }) => css`
    text-decoration: none;
    background-color: ${theme.colors.transparent};
    display: flex;
    align-items: center;
    padding: 0.5rem 2.5rem;
    color: ${theme.typography.fifth};
    margin: 0 1rem;
    font-size: 1.4rem;
    border-radius: 0.5rem;
    border: 0.1rem solid ${theme.colors.lightGray};
    cursor: pointer;
  `}
`;

export const Overlay = styled.div<HasOpen>`
  ${({ open }) =>
    css`
      ${!open && overlayModifiers.default()};
      ${open && overlayModifiers.open()};
    `}
`;

export const ClientWrapper = styled.button<HasActive>`
  ${({ theme, active }) => css`
    display: flex;
    align-items: center;
    flex: 1;
    margin: 1rem 0;
    border: none;
    border-color: transparent;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: all 0.35s ease-out;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.2rem;
      border: 0.2rem solid ${theme.colors.transparent};
    }
    ${avatarStyle.hover(theme)}
    ${active && avatarStyle.active(theme)}
  `}
`;

export const ClientName = styled.p`
  ${({ theme }) => css`
    width: auto;
    text-align: left;
    margin-left: 2rem;
    padding: 0;
    font-size: ${theme.font.sizes.medium};
    font-weight: 600;
    opacity: 1;
    color: ${theme.colors.darkBlue};
    background-color: ${theme.colors.transparent};
  `}
`;

export const ClientsWrapper = styled.div`
  ${({ theme }) => css`
    height: 40rem;
    max-height: 40rem;
    width: 100%;
    ::-webkit-scrollbar-track {
      background-color: ${theme.colors.transparent};
    }
    ::-webkit-scrollbar {
      width: 0.5rem;
      background-color: ${theme.colors.transparent};
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 5rem;
      background-color: ${theme.colors.gray};
      opacity: 0.9;
    }
    overflow-y: auto;
  `}
`;

export const Button = styled(MUIButton)<ButtonProps>`
  ${({ theme, fixedSize }) => css`
    height: 4rem;

    margin-top: 1rem !important;

    border: 1px solid ${theme.colors.black} !important;
    border-radius: 2.5rem !important;

    ${fixedSize &&
    css`
      width: 18rem;
    `}

    .MuiButton-label {
      font-size: ${theme.font.sizes.xsmall};
    }
  `}
`;

const gearIconModifier = css`
  filter: brightness(0%);
`;

export const GearIcon = styled.img<Disabled>`
  ${({ disabled }) =>
    css`
      ${!disabled && gearIconModifier};
    `}
  margin-right: 1rem;
`;

export const GearText = styled.p<Disabled>`
  ${({ theme, disabled }) => css`
    color: ${disabled ? theme.colors.primary : theme.colors.black};
  `}
`;
