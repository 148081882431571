import React, { useEffect, useRef, useState } from 'react';
import { LottieSkeleton } from '..';
import LoadingAnimation from '../../assets/lottie/loading.json';
import { useUploadAvatar } from '../../hook/useUploadAvatar';
import * as S from './styled';

const CONTROL_READY_STATE = 2;
const acceptTypes = 'image/png, image/gif, image/jpeg';

export type AvatarProps = {
  avatarPath?: string;
  setFile?: any;
  change?: boolean;
  name: string;
  round?: string | boolean;
  size?: string;
  onChange?: any;
  entityId: string;
  style?: Object;
};

export type CustomAvatarProps = Pick<AvatarProps, 'avatarPath' | 'setFile'>;

const Avatar = ({
  avatarPath = '',
  setFile,
  change = false,
  name,
  round = '5',
  size = '60',
  onChange,
  entityId,
  style,
}: AvatarProps) => {
  const [loading, setLoading] = useState(false);
  const [avatarSourcePath, setAvatarSourcePath] = useState(avatarPath || '');
  const inputFileRef = useRef<HTMLInputElement>(null);

  const uploadAvatar = useUploadAvatar();

  useEffect(() => {
    if (avatarPath) {
      setAvatarSourcePath(avatarPath);
    }
  }, [avatarPath]);

  const getLocalUpload = async (uploadEvent: any) => {
    setLoading(true);

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === CONTROL_READY_STATE) {
        setAvatarSourcePath(reader.result as string);
      }
    };

    const avatarFile = uploadEvent?.target?.files[0];

    const response = await uploadAvatar({
      file: avatarFile,
      entityId,
      apiCall: onChange,
    });

    if (response && setFile) {
      setFile(avatarFile);
      reader.readAsDataURL(uploadEvent.target.files[0]);
    }

    setLoading(false);
  };

  const onButtonClick = () => {
    if (inputFileRef.current) inputFileRef.current.click();
  };

  if (loading) {
    return (
      <S.Wrapper>
        <LottieSkeleton animation={LoadingAnimation} />
      </S.Wrapper>
    );
  }

  const returnAvatar = change ? (
    <>
      <S.Wrapper style={style}>
        <S.Avatar
          name={name}
          src={avatarSourcePath}
          size={size}
          onClick={onButtonClick}
          round={round}
          style={{ cursor: 'pointer' }}
          maxInitials={2}
          textSizeRatio={2}
        />
      </S.Wrapper>
      <S.Upload
        type="file"
        id="file"
        ref={inputFileRef}
        accept={acceptTypes}
        onChange={getLocalUpload}
      />
    </>
  ) : (
    <S.Avatar
      name={name}
      src={avatarSourcePath}
      size={size}
      round={round}
      maxInitials={2}
      style={style}
      textSizeRatio={2}
    />
  );

  return returnAvatar;
};

export default Avatar;
