import React from 'react';
import * as S from './styled';

export type LogoProps = {
  height?: number;
};

const Logo = ({ height = 7 }: LogoProps) => (
  <S.Wrapper height={height}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184 68.382">
      <g data-name="Grupo 29959">
        <g fill="#222137" data-name="Grupo 29954">
          <path
            d="M1.829 62.051v1.508H0V48.364h1.889v7.095h.06a3.979 3.979 0 011.343-1.2 4.048 4.048 0 012.045-.493 4.774 4.774 0 011.935.382 4.454 4.454 0 011.5 1.065 4.791 4.791 0 01.972 1.6 5.561 5.561 0 01.341 1.959 5.643 5.643 0 01-.341 1.97 4.857 4.857 0 01-.962 1.608 4.446 4.446 0 01-1.5 1.076 4.852 4.852 0 01-1.985.391 4.357 4.357 0 01-1.945-.452 3.658 3.658 0 01-1.484-1.316zm6.331-3.266a4.132 4.132 0 00-.2-1.283 3.269 3.269 0 00-.6-1.1 2.9 2.9 0 00-.995-.76 3.178 3.178 0 00-1.377-.281 3.029 3.029 0 00-1.316.281 3.1 3.1 0 00-1.015.76 3.513 3.513 0 00-.653 1.1 3.782 3.782 0 000 2.605 3.387 3.387 0 00.653 1.092 3.153 3.153 0 001.015.752 3.039 3.039 0 001.316.28 3.084 3.084 0 001.377-.291 3.006 3.006 0 00.995-.771 3.266 3.266 0 00.6-1.1 4.128 4.128 0 00.199-1.284z"
            data-name="Caminho 704"
          />
          <path
            d="M15.294 61.508h.04l2.632-7.476h2.009l-4.622 11.777a7.638 7.638 0 01-.522 1.075 3.274 3.274 0 01-.663.8 2.736 2.736 0 01-.894.513 3.654 3.654 0 01-1.216.181q-.3 0-.613-.03a4.388 4.388 0 01-.633-.111l.181-1.667a2.993 2.993 0 00.945.161 1.394 1.394 0 001.105-.412 4.037 4.037 0 00.663-1.176l.6-1.587-4.08-9.526h2.11z"
            data-name="Caminho 705"
          />
          <path
            d="M24.799 49.329h4.9a7.294 7.294 0 011.607.182 4.239 4.239 0 011.437.6 3.25 3.25 0 011.024 1.105 3.4 3.4 0 01.392 1.709 2.969 2.969 0 01-.683 1.989 3.493 3.493 0 01-1.809 1.105v.04a4.112 4.112 0 011.266.352 3.3 3.3 0 011.005.714 3.1 3.1 0 01.652 1.045 3.74 3.74 0 01.231 1.347 3.519 3.519 0 01-1.567 3.044 5.353 5.353 0 01-1.7.744 8.312 8.312 0 01-2.08.251h-4.675zm1.99 6.009h2.592a4.3 4.3 0 001.075-.13 2.643 2.643 0 00.894-.4 1.97 1.97 0 00.6-.683 2.082 2.082 0 00.221-.995 1.745 1.745 0 00-.844-1.638A4.5 4.5 0 0029.056 51h-2.267zm0 6.532h2.492a7.3 7.3 0 001.256-.111 3.65 3.65 0 001.146-.391 2.3 2.3 0 00.824-.754 2.129 2.129 0 00.312-1.2 2.021 2.021 0 00-.864-1.829 4.582 4.582 0 00-2.492-.563h-2.674z"
            data-name="Caminho 706"
          />
          <path
            d="M46.182 58.774a5.15 5.15 0 01-.392 2.03 4.821 4.821 0 01-1.085 1.6 4.888 4.888 0 01-1.638 1.045 5.726 5.726 0 01-4.059 0 4.763 4.763 0 01-1.628-1.045 4.9 4.9 0 01-1.076-1.6 5.15 5.15 0 01-.391-2.03 5.08 5.08 0 01.391-2.02 4.756 4.756 0 012.7-2.612 5.717 5.717 0 014.059 0 4.98 4.98 0 011.638 1.034 4.739 4.739 0 011.089 1.577 5.08 5.08 0 01.392 2.021zm-1.97.011a4.017 4.017 0 00-.212-1.3 3.292 3.292 0 00-.615-1.092 2.946 2.946 0 00-1-.751 3.438 3.438 0 00-2.721 0 2.946 2.946 0 00-1 .751 3.293 3.293 0 00-.615 1.092 4.121 4.121 0 000 2.6 3.393 3.393 0 00.615 1.1 2.882 2.882 0 001 .762 3.446 3.446 0 002.721 0 2.882 2.882 0 001-.762 3.392 3.392 0 00.615-1.1 4.006 4.006 0 00.213-1.3z"
            data-name="Caminho 707"
          />
          <path
            d="M48.433 54.027l2.151 7.275h.04l2.271-7.275h1.991l2.312 7.275h.04l2.148-7.275h1.989l-3.155 9.526h-1.97l-2.352-7.155h-.04l-2.331 7.155h-1.97l-3.171-9.526z"
            data-name="Caminho 708"
          />
          <path
            d="M71.102 58.714v.322a2.708 2.708 0 01-.02.321h-7.577a2.81 2.81 0 00.272 1.128 2.969 2.969 0 00.673.919 3.161 3.161 0 002.151.827 3.181 3.181 0 001.668-.422 3.408 3.408 0 001.105-1.025l1.327 1.065a4.746 4.746 0 01-1.8 1.491 5.772 5.772 0 01-4.31.116 4.637 4.637 0 01-1.588-1.015 4.7 4.7 0 01-1.045-1.578 5.323 5.323 0 01-.382-2.05 5.463 5.463 0 01.372-2.04 4.657 4.657 0 011.037-1.6 4.752 4.752 0 011.571-1.034 5.385 5.385 0 013.857-.03 3.928 3.928 0 011.45.984 4.436 4.436 0 01.916 1.557 6.18 6.18 0 01.323 2.064zm-1.93-.764a3.59 3.59 0 00-.191-1.055 2.437 2.437 0 00-.5-.854 2.365 2.365 0 00-.824-.572 2.914 2.914 0 00-1.156-.212 3.044 3.044 0 00-1.135.212 2.949 2.949 0 00-.925.572 3.027 3.027 0 00-.643.854 2.73 2.73 0 00-.292 1.055z"
            data-name="Caminho 709"
          />
        </g>
        <path
          fill="#4a45d8"
          d="M76.778 32.001a4.114 4.114 0 014.211 4 4.214 4.214 0 11-4.211-4z"
          data-name="Caminho 710"
        />
        <g data-name="Grupo 29957">
          <g data-name="Grupo 29956">
            <g fill="#222137" data-name="Grupo 29955">
              <path
                d="M9.089 40.263a10.544 10.544 0 01-4.594-.986 7.494 7.494 0 01-3.283-3.03A10.134 10.134 0 010 31.021v-17.12h5.806v18.027a3.514 3.514 0 00.455 1.792 3.076 3.076 0 001.186 1.186 3.481 3.481 0 003.256 0 3.024 3.024 0 001.213-1.186 3.507 3.507 0 00.455-1.792v-9.493a10.216 10.216 0 011.135-5.023 7.381 7.381 0 013.182-3.081 11.107 11.107 0 019.367 0 7.59 7.59 0 013.182 3.081 10.049 10.049 0 011.16 5.023v9.493a3.487 3.487 0 00.455 1.792 3.026 3.026 0 001.211 1.186 3.471 3.471 0 001.666.4 3.231 3.231 0 001.591-.4 3.1 3.1 0 001.188-1.186 3.506 3.506 0 00.453-1.792V13.901h5.806v17.117a10.134 10.134 0 01-1.211 5.226 7.5 7.5 0 01-3.282 3.03 10.971 10.971 0 01-9.091 0 7.815 7.815 0 01-3.332-3.03 9.814 9.814 0 01-1.261-5.226v-9.391a3.6 3.6 0 00-.43-1.843 3.019 3.019 0 00-1.161-1.135 3.345 3.345 0 00-1.641-.4 3.283 3.283 0 00-1.565.4 3.156 3.156 0 00-1.213 1.135 3.45 3.45 0 00-.453 1.843v9.391a9.954 9.954 0 01-1.238 5.226 7.679 7.679 0 01-3.281 3.03 10.273 10.273 0 01-4.519.989z"
                data-name="Caminho 711"
              />
              <path
                d="M60.693 39.653a16.676 16.676 0 01-7.6-1.641 12.117 12.117 0 01-6.892-11.235 14.7 14.7 0 011.615-7.019 12.078 12.078 0 014.443-4.746 12.3 12.3 0 016.514-1.718 12.134 12.134 0 016.817 1.769 10.594 10.594 0 013.964 4.746 16.635 16.635 0 011.287 6.665c0 .337-.017.725-.051 1.161a5.437 5.437 0 01-.151 1.01H52.362a6.451 6.451 0 001.464 3.358 7.258 7.258 0 002.929 2.02 10.871 10.871 0 003.939.682h6.917v4.948zm-8.382-14.946h12.725a10.814 10.814 0 00-.278-1.919 7.09 7.09 0 00-.706-1.818 5.521 5.521 0 00-1.211-1.489 5.76 5.76 0 00-1.743-1.01 6.609 6.609 0 00-2.323-.379 5.9 5.9 0 00-2.675.582 6.275 6.275 0 00-1.969 1.514 6.708 6.708 0 00-1.237 2.121 11.055 11.055 0 00-.583 2.397z"
                data-name="Caminho 712"
              />
            </g>
          </g>
        </g>
        <g fill="#222137" data-name="Grupo 29958">
          <path
            d="M104.013 16.327a12.925 12.925 0 00-8.215-2.958 13.5 13.5 0 000 27 12.925 12.925 0 008.215-2.958v2.383h5.356V0h-5.356zm-8.215 18.682a8.148 8.148 0 117.827-8.139 8 8 0 01-7.827 8.14z"
            data-name="Caminho 713"
          />
          <path
            d="M124.408 13.302a15.724 15.724 0 00-10.537 4.2l3.717 3.856a10.46 10.46 0 016.819-2.7c4.031 0 5.966 1.645 6.188 5.31-7.887 0-17.332 1.064-17.332 8.579 0 5.895 5.421 7.842 9.474 7.842 3.579 0 6.579-1.632 7.842-3.842h.037v3.245h5.356V24.714c.003-7.145-4.32-11.412-11.564-11.412zm-.406 22.3c-2.158 0-4.422-.737-4.422-3.159 0-3.737 5.632-4.211 9.632-4.211h1.406v1.313c-.158 3.749-2.454 6.054-6.615 6.054z"
            data-name="Caminho 714"
          />
          <path
            d="M149.396 18.659a7.6 7.6 0 015.151 2.456l4.056-3.5a12.929 12.929 0 00-9.207-4.313c-6.062 0-10.3 3.417-10.3 8.309 0 5.9 5.844 7.2 7.779 7.624l.226.049c3.628.776 5.877 1.6 5.877 3.32 0 2.5-4.411 2.612-4.456 2.612-4.862 0-6.757-2.115-6.956-2.356l-4.124 3.417c.835 1.008 4.1 4.3 11.08 4.3 4.875 0 9.811-2.738 9.811-7.968 0-6.4-7.086-7.911-10.112-8.557l-.206-.044c-3.563-.788-3.563-1.676-3.563-2.391.005-2.876 4.444-2.958 4.944-2.958z"
            data-name="Caminho 715"
          />
          <path
            d="M173.493 13.302a11.831 11.831 0 00-6.389 1.816V0h-5.356v39.791h5.356V25.109h.053a6.185 6.185 0 016.336-6.452c3.263 0 5.151 1.092 5.151 6.674v14.46H184v-14.46c0-9.943-5.717-12.029-10.507-12.029z"
            data-name="Caminho 716"
          />
        </g>
      </g>
    </svg>
  </S.Wrapper>
);

export default Logo;
