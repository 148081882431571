import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';
import App from './App';
import LOGO from './assets/img/logo.png';
import SEO from './components/SEO';
import GlobalStyles from './styles/global';
import { materialTheme } from './styles/material.theme';
import theme from './styles/theme';

const openGraphProtocol = {
  lang: 'eng',
  title: 'We.Dash',
  image: LOGO,
  url: 'https://backoffice.wedash.digital/',
  author: 'Bowe',
  description: 'Dashboards de marketing, vendas e growth em um só lugar.',
};

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <MaterialThemeProvider theme={materialTheme}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <SEO {...openGraphProtocol} />
          <App />
        </ThemeProvider>
      </MaterialThemeProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
