/* eslint-disable no-unused-vars */
import styled, { css } from 'styled-components';
import { Box as MUIBOX } from '@material-ui/core';
import { LayoutProps } from './index';

import BLUE_LINE from '../../assets/img/blueLineImg.svg';
import GREEN_LINE from '../../assets/img/greenLineImg.svg';

export const Box = styled(MUIBOX)<LayoutProps>`
  ${({ theme, background, afterProp, beforeProp }) => css`
    position: relative;
    background: ${theme.colors[background!]};

    ${afterProp &&
    css`
      &:after {
        position: absolute;
        content: '';
        background-image: url(${BLUE_LINE});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        bottom: 0;
        left: 0%;
        width: 99vw;
        height: 510px;
        z-index: -1;
      }
    `}

    ${beforeProp &&
    css`
      &:before {
        position: absolute;
        content: '';
        background-image: url(${GREEN_LINE});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        bottom: 0;
        left: -2%;
        width: 99vw;
        height: 510px;
        z-index: -1;
      }
    `}
  `}
`;
