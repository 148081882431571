/* eslint-disable no-param-reassign */
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import React from 'react';
import { Controller } from 'react-hook-form';
import * as S from '../styled';
import { CurrencyProps } from '../types/currency.type';

const Currency = ({ name, control, label, error, title }: CurrencyProps) => (
  <S.InputWrapper title={title}>
    {title && <S.Title>{title}</S.Title>}
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ...rest } }) => (
        <CurrencyTextField
          {...rest}
          id="outlined-required"
          variant="outlined"
          label={label}
          type="currency"
          decimalCharacter=","
          digitGroupSeparator="."
          currencySymbol="R$"
          value={value}
          onChange={(_: any, inputValue: any) => onChange(inputValue)}
        />
      )}
    />
    {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
  </S.InputWrapper>
);
export default Currency;
