import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import ERROR_ICON from '../../assets/icon/errorIcon.svg';
import SUCESS_ICON from '../../assets/icon/sucessIcon.svg';
import { InputPassword, Layout, Logo } from '../../components';
import { useQuery } from '../../hook/useQuery';
import { createPassword } from '../../services/auth';
import NewPasswordSchema from '../../validations/NewPasswordSchema';
import * as S from './styled';

type ErrorMessage = 'Unauthorized' | null | undefined;

type Params = {
  authId: string;
  token: string;
  name: string;
};
const ErrorMapping = {
  Unauthorized: 'Email ou senha inválidos',
};

const CreatePassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [userData, setUserData] = useState({ authId: '', token: '' });
  const [isFormBlocked, setIsFormBlocked] = useState(false);
  const params = useParams<Params>();
  const redirect = useHistory();
  const query = useQuery();

  useEffect(() => {
    const [authId, token, name] = [
      query.get('authId'),
      query.get('token'),
      query.get('name'),
    ] as string[];

    if (!authId || !token || !name) {
      setIsFormBlocked(true);
      setError('Parâmetros obrigatórios não informados!');
    }

    setUsername(name || 'Usuário');
    setUserData({ authId, token });
  }, [params]);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(NewPasswordSchema),
  });

  const watchPassword = watch('password', '');
  const passwordHasLowercaseCharacter = /^(?=.*?[a-z])/.test(watchPassword);
  const passwordHasUppercaseCharacter = /^(?=.*?[A-Z])/.test(watchPassword);
  const passwordHasSpecialCharacter = /^(?=.*?[#?!@$%^&*-.;:|,])/.test(
    watchPassword,
  );
  const passwordHasNumberCharacter = /^(?=.*?[0-9])/.test(watchPassword);
  const passwordAtLeastEightCharacters = /^.{8,}$/.test(watchPassword);

  const passwordRequirements = [
    {
      text: 'No minímo 1 letra maiúscula',
      imgProps: passwordHasUppercaseCharacter,
    },
    {
      text: 'No minímo 1 letra minúscula',
      imgProps: passwordHasLowercaseCharacter,
    },
    { text: 'No minímo 1 número', imgProps: passwordHasNumberCharacter },
    {
      text: 'No minímo 1 caractere especial',
      imgProps: passwordHasSpecialCharacter,
    },
    {
      text: 'No minímo 8 caracteres',
      imgProps: passwordAtLeastEightCharacters,
    },
  ];

  const onSubmit = async (data: any) => {
    if (isFormBlocked) return;

    try {
      setLoading(true);
      await createPassword({ ...userData, ...data });
      redirect.push('/');
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message as ErrorMessage;

      if (e?.response?.data?.field === 'token') {
        setError('O token informado na URL não é válido');
      } else if (e?.response?.data?.field === 'password') {
        setError(`Esse usuário já possui uma senha`);
      } else if (!errorMessage) {
        setError('Erro interno, tente novamente mais tarde');
      } else {
        const err =
          ErrorMapping[errorMessage] ||
          'Erro interno, tente novamente mais tarde';
        setError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const returnImgSrc = (requeriment: boolean) => {
    if (requeriment) {
      return SUCESS_ICON;
    }
    return ERROR_ICON;
  };

  const returnImgAlt = (requeriment: boolean) => {
    if (requeriment) {
      return 'Símbolo circular indicando que a etapa foi concluída com sucesso.';
    }
    return 'Símbolo circular indicando que a etapa não foi concluída com sucesso.';
  };

  return (
    <Layout afterProp beforeProp>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent={{ md: 'space-between' }}
      >
        <Box py={{ xs: 1, sm: 3 }}>
          <a href="https://app.wedash.digital/">
            <Logo />
          </a>
        </Box>
        <Box py={{ xs: 2, sm: 3 }}>
          <Card elevation={2}>
            <Box p={{ xs: 2, md: 5 }} display="flex">
              <S.Form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h3">{`Olá, ${username}.`}</Typography>
                <Typography variant="h5" color="textSecondary">
                  Por favor digite sua senha abaixo:
                </Typography>
                <Box py={{ xs: 2, sm: 4 }}>
                  <InputPassword
                    control={control}
                    error={errors.password?.message}
                    label="Senha"
                    name="password"
                    disabled={isFormBlocked}
                  />
                  <InputPassword
                    control={control}
                    error={errors.passwordConfirmation?.message}
                    label="Confirmar a senha"
                    name="passwordConfirmation"
                    disabled={isFormBlocked}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading || isFormBlocked}
                  fullWidth
                >
                  Alterar senha
                </Button>
                <S.ErrorMessage>{error}</S.ErrorMessage>
                <Box py={3} display="flex" flexDirection="column">
                  <Typography variant="body1">
                    Sua senha deve conter ao menos:
                  </Typography>
                  {passwordRequirements.map(requeriment => (
                    <Box display="flex" alignItems="center" py={1}>
                      <S.Img
                        src={returnImgSrc(requeriment.imgProps)}
                        alt={returnImgAlt(requeriment.imgProps)}
                      />
                      <Typography variant="body1">
                        {requeriment.text}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </S.Form>
            </Box>
          </Card>
        </Box>
      </Box>
    </Layout>
  );
};

export default CreatePassword;
