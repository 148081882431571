import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ClientData } from '../../services/client';
import { DashData } from '../../services/dash';
import { UserData } from '../../services/user';
import Avatar from '../Avatar';
import DropDownManageAccount from '../DropDownManageAccount';
import Logo from '../Logo';
import * as S from './styled';

export type RoundedImageProps = {
  size: 'small' | 'medium';
  ml?: boolean;
  mr?: boolean;
};

export type ButtonProps = {
  fixedSize?: boolean;
};

export type Disabled = {
  disabled?: boolean;
};

export type MenuProps = {
  dashId: string;
  elevation?: number;
  userData?: UserData;
  clientData?: ClientData;
  dashData?: DashData;
  atProfile?: boolean;
} & Disabled;

const Menu = ({
  dashId,
  disabled = false,
  elevation = 0,
  userData,
  clientData,
  dashData,
  atProfile = false,
}: MenuProps) => {
  const history = useHistory();

  const clientsHiddenLogo = ['a06200b6-248e-4d34-8b64-5b984dcbc63f', 'c143f257-40e9-45f3-8267-5704391d9f2f']

  const handleNavigateToWelcome = useCallback(() => {
    history.push(`/welcome?clientId=${clientData?.id}`);
  }, []);

  const handleNavigateToDash = useCallback(() => {
    history.replace(`/app/${dashId}`);
  }, []);

  return (
    <S.Wrapper elevation={elevation}>
      <div>
        {!clientsHiddenLogo.includes(clientData?.id as string) && <>
          <S.Button
            style={{ backgroundColor: 'transparent' }}
            type="button"
            onClick={handleNavigateToWelcome}
          >
            <Logo height={4} />
          </S.Button>
          <S.Pipe />
        </>}
        <Avatar
          entityId={clientData?.id || ''}
          name={clientData?.name || ''}
          size="38"
          round
          style={{ marginRight: 16 }}
          avatarPath={clientData?.avatarUrl}
        />
        <S.Text>
          {clientData?.name} - Dashboard {dashData?.name}
        </S.Text>
      </div>
      <div>
        <S.Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleNavigateToWelcome}
        >
          Alterar dashboard
        </S.Button>
        {atProfile && (
          <>
            <S.Pipe />
            <S.Button
              style={{ backgroundColor: 'transparent' }}
              onClick={handleNavigateToDash}
              disabled={disabled}
            >
              <S.GearText disabled={disabled}>Retornar à dashboard</S.GearText>
            </S.Button>
          </>
        )}
        <S.Pipe />
        <S.Text>{userData?.name}</S.Text>
        <DropDownManageAccount
          email={userData?.email}
          key={userData?.id}
          profilePhoto={userData?.avatarUrl}
          name={userData?.name}
          company={userData?.company}
          dash={dashData}
        />
      </div>
    </S.Wrapper>
  );
};

export default Menu;
