import { Box as StyledBox, Card as StyledCard } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const TemporaryAvatar = styled.img`
  width: 18rem;
  border-radius: 50%;
`;

export const Divider = styled.div`
  width: 100%;
  border: 0.05rem solid rgba(0, 0, 0, 0.1);
  margin: 4rem 0 2rem 0;
`;

export const Box = styled(StyledBox)`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  > * + * {
    margin: 0 3rem;
  }
`;

export const Card = styled(StyledCard)`
  min-width: 60%;
  min-height: 50rem;
`;

export const AvatarCard = styled(StyledCard)`
  min-width: 13%;
  min-height: 35rem;

  @media (max-width: 1440px) {
    min-width: 25%;
  }
`;

export const TextWrapper = styled.div`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.darkGray};
    > p + p {
      margin-top: 1rem;
    }

    > p:first-child {
      font-weight: ${theme.font.semiBold};
    }
  `}
`;
