import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputText } from '..';
import RecoveryPassword from '../../validations/RecoveryPassword';
import ModalSkeleton from '../ModalSkeleton';
import * as S from './styled';

type ModalRecoveryPasswordProps = {
  opened: boolean;
  title?: string;
  buttonText: string;
  onSend?: any;
  onCancel: any;
};

const ModalRecoveryPassword = ({
  onSend,
  title,
  opened,
  onCancel,
  buttonText,
}: ModalRecoveryPasswordProps) => {
  const [emailValue, setEmailValue] = useState('');
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RecoveryPassword),
  });

  const watchEmail = watch('email', '');

  const onSubmit = (data: any) => {
    onSend(data, emailValue);
    onCancel();
    reset();
  };

  useEffect(() => {
    setEmailValue(watchEmail);
  }, [watchEmail]);

  return (
    <ModalSkeleton
      buttonText={buttonText}
      onSend={handleSubmit(onSubmit)}
      title={title}
      opened={opened}
      onCancel={onCancel}
    >
      <S.Wrapper>
        <S.Title>
          Para recuperar sua senha, precisamos do endereço de e-mail vinculado à
          sua conta.
        </S.Title>
        <InputText
          control={control}
          error={errors.email?.message}
          name="email"
          label="E-mail"
        />
      </S.Wrapper>
    </ModalSkeleton>
  );
};

export default ModalRecoveryPassword;
