import { Typography } from '@material-ui/core';
import { Add, Edit, Remove } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { DatePicker } from '../../../../components';
import Currency from '../../../../components/Inputs/Currency';
import Select from '../../../../components/Inputs/Select';
import Text from '../../../../components/Inputs/Text';
import ModalDeleteGoal from '../../../../components/ModalDeleteGoal';
import ModalEditGoal from '../../../../components/ModalEditGoal';
import { numberToCurrency } from '../../../../helpers/formatNumbers';
import { useSnackbar } from '../../../../providers/snackbar';
import { ClientData } from '../../../../services/client';
import {
  deleteGoal,
  editGoal,
  getAllGoals,
  saveGoal
} from '../../../../services/goals';
import useAPI from '../../../../services/useApi';
import * as TabStyles from '../styled';
import * as S from './styled';

export type SessionProps = {
  scroll?: boolean;
};

export type ActionButtonProps = {
  bgColor: string;
};

export const kpiMap = [
  {
    name: 'Leads qualificados',
    value: 'QUALIFIED_LEAD',
    type: 'number',
  },
  {
    name: 'Leads',
    value: 'LEAD',
    type: 'number',
  },
  {
    name: 'Oportunidades',
    value: 'OPORTUNITY',
    type: 'number',
  },
  {
    name: 'Verba',
    value: 'BUDGET',
    type: 'currency',
  },
  {
    name: 'Custo por lead',
    value: 'LEAD_COST',
    type: 'currency',
  },
  {
    name: 'Custo por Oportunidade',
    value: 'OPPORTUNITY_COST',
    type: 'currency',
  },
];

type GoalsProps = {
  clientData?: ClientData;
};

export type GoalItemProps = {
  deletedAt: Date;
  endAt: Date;
  id: string;
  kpi: string;
  startAt: Date;
  value: string;
};

const Goals = ({ clientData }: GoalsProps) => {
  const [goals, setGoals] = useState<GoalItemProps[]>();
  const [selectedGoal, setSelectedGoal] = useState({});
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const callAPI = useAPI();

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      kpi: '',
      value: '',
      startAt: new Date(),
      endAt: new Date(),
    },
  });

  const selectedKPI = watch('kpi');

  const { customColor, alert } = useTheme();

  const fetchGoals = useCallback(async () => {
    if (clientData) {
      const params = {
        clientId: clientData.id,
      };
      const data: GoalItemProps[] = await callAPI(getAllGoals, params);
      if (data) setGoals(data.reverse());
    }
  }, []);

  const onSubmit = useCallback(async formData => {
    if (clientData) {
      const requestBody = {
        ...formData,
        clientId: clientData.id,
      };

      try {
        const saveResponse = await callAPI(saveGoal, requestBody);
        showSnackbar({
          type: 'success',
          message: 'Meta cadastrada com sucesso!',
        });
        if (saveResponse) {
          await fetchGoals();
        }
        reset();
      } catch (err) {
        showSnackbar({
          type: 'error',
          message:
            'Não foi possível salvar sua meta, tente novamente mais tarde.',
          duration: 5000,
        });
      }
    }
  }, []);

  const getKPIName = (kpi: string) =>
    kpiMap.find(item => item.value === kpi)?.name;

  const onEdit = useCallback(async params => {
    const response = await callAPI(editGoal, params);
    showSnackbar({
      type: 'success',
      message: 'Meta editada com sucesso',
    });
    if (response) await fetchGoals();
  }, []);

  const closeEditModal = useCallback(() => {
    setSelectedGoal({});
    setEditIsOpen(false);
  }, []);

  const onDeleteGoal = useCallback(async () => {
    try {
      const response = await callAPI(deleteGoal, selectedGoal);
      showSnackbar({
        type: 'success',
        message: 'Meta excluída com sucesso!',
      });
      if (response) {
        await fetchGoals();
      }
    } catch (err) {
      showSnackbar({
        type: 'error',
        message:
          'Algo deu errado ao editar a sua meta, tente novamente mais tarde',
        duration: 5000,
      });
    } finally {
      setDeleteIsOpen(false);
    }
  }, [selectedGoal]);

  const onCancelDeleteModal = useCallback(() => {
    setDeleteIsOpen(false);
  }, []);

  const setDeleteItem = useCallback(goal => {
    setSelectedGoal(goal);
    setDeleteIsOpen(true);
  }, []);

  const setEditableItem = useCallback(goal => {
    setSelectedGoal(goal);
    setEditIsOpen(true);
  }, []);

  useEffect(() => {
    fetchGoals();
  }, []);

  const isKPINumeric = (kpi: string) =>
    kpiMap.find(item => item.value === kpi)?.type === 'number';

  const formatValue = (goal: GoalItemProps) =>
    isKPINumeric(goal.kpi)
      ? goal.value
      : numberToCurrency(Number.parseFloat(goal.value));

  return (
    <S.Wrapper>
      <ModalEditGoal
        buttonText="Salvar"
        title="Editar meta"
        opened={editIsOpen}
        edit={onEdit}
        closeOnSubmit={closeEditModal}
        onCancel={closeEditModal}
        goal={selectedGoal}
      />
      <ModalDeleteGoal
        buttonText="Excluir"
        title="Excluir meta"
        opened={deleteIsOpen}
        onSubmit={onDeleteGoal}
        onCancel={onCancelDeleteModal}
      />
      <S.Session>
        <TabStyles.SessionTitle>Criar metas</TabStyles.SessionTitle>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Select
              name="kpi"
              error={errors.kpi?.message}
              control={control}
              options={kpiMap}
              title="KPI"
            />
          </div>
          <div>
            {isKPINumeric(selectedKPI) ? (
              <Text
                name="value"
                error={errors.value?.message}
                control={control}
                title="Valor da meta"
              />
            ) : (
              <Currency
                name="value"
                error={errors.value?.message}
                control={control}
                title="Valor da meta"
              />
            )}
          </div>
          <div>
            <DatePicker
              name="startAt"
              error={errors.startAt?.message}
              control={control}
              title="Data início"
            />
          </div>
          <div>
            <DatePicker
              name="endAt"
              error={errors.endAt?.message}
              control={control}
              title="Data fim"
            />
          </div>
          <S.ActionButton bgColor={customColor.violet.a10} type="submit">
            <Add color="primary" style={{ fontSize: 15, fontWeight: 'bold' }} />
          </S.ActionButton>
        </S.Form>
      </S.Session>
      <S.Session scroll>
        <TabStyles.SessionTitle>Minhas metas</TabStyles.SessionTitle>
        {goals?.map(goal => (
          <S.GoalItem key={goal.id}>
            <S.GoalInfo>
              <Typography variant="h6">KPI</Typography>
              <Typography variant="h6">Valor da meta</Typography>
              <Typography variant="h6">Data início</Typography>
              <Typography variant="h6">Data fim</Typography>
              <Typography variant="body1">{getKPIName(goal.kpi)}</Typography>
              <Typography variant="body1">{formatValue(goal)}</Typography>
              <Typography variant="body1">
                {format(new Date(goal.startAt), 'dd/MM/yyyy')}
              </Typography>
              <Typography variant="body1">
                {format(new Date(goal.endAt), 'dd/MM/yyyy')}
              </Typography>
            </S.GoalInfo>
            <S.ActionButton
              bgColor={customColor.violet.a10}
              onClick={() => setEditableItem(goal)}
            >
              <Edit color="primary" style={{ fontSize: 15 }} />
            </S.ActionButton>
            <S.ActionButton
              bgColor={customColor.error.a10}
              onClick={() => setDeleteItem(goal)}
            >
              <Remove style={{ fontSize: 15, color: alert.error }} />
            </S.ActionButton>
          </S.GoalItem>
        ))}
      </S.Session>
    </S.Wrapper>
  );
};

export default Goals;
