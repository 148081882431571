import decodeJWT from 'jwt-decode';
import { API } from './api';

type TokenPayload = {
  sub: string;
  email: string;
  name: string;
  companyId: string;
  isBoweAdmin: boolean;
};

const [ACCESS_TOKEN, REFRESH_TOKEN] = ['ACCESS_TOKEN', 'REFRESH_TOKEN'];

export const signIn = async (credentials: any) => {
  const api = await API({ refresh: false });

  const result = await api.post('/auth/login', credentials);
  const { data } = result;
  localStorage.setItem(ACCESS_TOKEN, data.access_token);
  localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
  return result;
};

export const getUserInfo = async () => {
  const api = await API({ auth: true });

  const result = await api.get('/user/current');
  const { data } = result;

  return data;
};

export const isLoggedIn = getUserInfo;

export const refreshToken = async () => {
  const api = await API({ auth: false, refresh: false });

  const oldAccessToken = localStorage.getItem(ACCESS_TOKEN);
  const oldRefreshToken = localStorage.getItem(REFRESH_TOKEN);

  if (oldRefreshToken && oldAccessToken) {
    const { data } = await api.post('/auth/refresh', {
      access_token: oldAccessToken,
      refresh_token: oldRefreshToken,
    });

    localStorage.setItem(ACCESS_TOKEN, data.access_token);
    localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
  }
};

export const getUserCompany = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  if (accessToken) {
    const { companyId } = decodeJWT<TokenPayload>(accessToken);
    return companyId;
  }
  return null;
};

export const logout = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);

  window.location.replace('/');
};

export const createPassword = async ({
  authId,
  token,
  password,
  passwordConfirmation,
}: any) => {
  const api = await API({ auth: false, refresh: false });
  const { data } = await api.post('/auth/create-password', {
    authId,
    token,
    password,
    passwordConfirmation,
  });

  return data;
};

export const getCurrentuser = async () => {
  const api = await API({ auth: true, refresh: true });
  await api.get('/user/current');
};

export const checkAuth = async () => {
  try {
    const localAccessToken = localStorage.getItem(ACCESS_TOKEN);
    const localRefreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (localAccessToken && localRefreshToken) {
      await getCurrentuser();
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

type UserProps = {
  id: string;
};

export const getUserId = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (!token) return null;
  const { id } = decodeJWT(token) as UserProps;
  return id;
};
