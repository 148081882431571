import React from 'react';
import { useParams } from 'react-router-dom';
import { AppTemplate } from '../../templates';

type Params = {
  dashId: string;
};

const App = () => {
  const { dashId } = useParams<Params>();
  return <AppTemplate dashId={dashId} />;
};

export default App;
