import { Box, Card } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import WEDASH_LOGO from '../../assets/img/logo.png';
import { Layout, VerticalStepper } from '../../components';
import { getUserCompany } from '../../services/auth';
import { getClientsByCompany } from '../../services/client';
import { getDashsByClient } from '../../services/dash';
import useAPI from '../../services/useApi';
import * as S from './styled';

const Welcome = () => {
  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState([]);
  const [dashData, setDashData] = useState([]);
  const callApi = useAPI();

  const fetchClients = useCallback(async () => {
    setLoading(true);
    const companyId = getUserCompany();
    const clients = await callApi<typeof getClientsByCompany, any>(
      getClientsByCompany,
      { companyId },
    );
    if (clients) {
      setClientData(clients.result);
    }
    setLoading(false);
  }, [callApi]);

  const fetchDashs = useCallback(
    async clientId => {
      setLoading(true);
      const dashs = await callApi(getDashsByClient, { clientId });
      if (dashs) {
        setDashData(dashs.result);
      }
      setLoading(false);
    },
    [callApi],
  );

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  return (
    <Layout>
      <>
        <S.ImageWrapper
          src={WEDASH_LOGO}
          alt="Logo da empresa em duas linhas alinhadas a esquerda, na primeira linha sestá escrito we.dash e na segunda linha está escrito by bowe"
        />
        <Card elevation={3}>
          <Box p={3}>
            <VerticalStepper
              dashData={dashData}
              clientData={clientData}
              getDashs={fetchDashs}
              loading={loading}
            />
          </Box>
        </Card>
      </>
    </Layout>
  );
};

export default Welcome;
