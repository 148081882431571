/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useTheme } from 'styled-components';
import Button from '../Button';
import * as S from './styled';

export type BulletProps = {
  current: boolean;
};

type HorizontalStepperProps = {
  leftButtonText: string;
  rightButtonText: string;
  onFinish: any;
  children: React.ReactNode;
};

const HorizontalStepper = ({
  leftButtonText,
  rightButtonText,
  onFinish,
  children,
}: HorizontalStepperProps) => {
  // TODO tipar slider
  const sliderRef = useRef<any>();
  const [stepsQuantity, setStepsQuantity] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const { colors } = useTheme();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => setCurrentStep(next),
  };

  useEffect(() => {
    if (sliderRef.current) {
      setStepsQuantity(sliderRef.current.props.children.length);
    }
  }, [sliderRef]);

  const handlePrevStep = useCallback(() => {
    sliderRef.current.slickPrev();
  }, [sliderRef]);

  const handleNextStep = useCallback(() => {
    sliderRef.current.slickNext();
  }, [sliderRef]);

  const firstStep = currentStep === 0;
  const lastStep = currentStep === stepsQuantity - 1;

  const leftButtonProps = firstStep
    ? { text: leftButtonText, onClick: onFinish }
    : { text: 'Voltar', onClick: handlePrevStep };
  const rightButtonProps = lastStep
    ? { text: rightButtonText, onClick: onFinish }
    : { text: 'Próximo', onClick: handleNextStep };

  return (
    <S.Wrapper>
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
      <S.ButtonsWrapper>
        <Button
          type="button"
          text={leftButtonProps.text}
          variant="outlined"
          fullWidth
          style={{ borderColor: colors.gray, color: colors.gray }}
          onClick={leftButtonProps.onClick}
        />
        <Button
          type="button"
          text={rightButtonProps.text}
          fullWidth
          onClick={rightButtonProps.onClick}
        />
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default HorizontalStepper;
