import { API } from './api';

export type ClientData = {
  id: string;
  name: string;
  avatarUrl?: string;
  active: boolean;
  hiddenLogo?: boolean;
};

export type ClientDataResponse = {
  total: number;
  result: ClientData[];
};

export type GetClientsByCompanyProps = {
  companyId: string;
};

export const getClientsByCompany = async ({
  companyId,
}: GetClientsByCompanyProps) => {
  const api = await API({ auth: true, refresh: true });

  return api.get<ClientDataResponse>(`client?companyId=${companyId}`);
};

export const getClientByDashId = async ({ dashId }: any) => {
  const api = await API({ auth: true, refresh: true });

  return api.get<ClientData>(`client/dash/${dashId}`);
};
