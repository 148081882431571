import { Tabs as MUITabs } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Tabs = styled(MUITabs)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 0.1rem solid transparent;
    border-radius: 1rem 1rem 0 0;
    width: 100%;
    .Mui-selected {
      color: ${theme.colors.primary};
    }
    .MuiTabs-indicator {
      background-color: ${theme.colors.primary};
    }
  `}
`;
