import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { Button, InputText } from '../../../../components';
import { CompanyData } from '../../../../services/company';
import { UserData } from '../../../../services/user';
import * as TabStyles from '../styled';
import * as S from './styled';

type AccountDetailsProps = {
  userData?: UserData;
  companyData?: CompanyData;
  changeUserData: any;
};

const AccountDetails = ({
  userData,
  companyData,
  changeUserData,
}: AccountDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { colors } = useTheme();

  // eslint-disable-next-line no-unused-vars
  const handleChangeEditing = useCallback(() => {
    setIsEditing(previous => !previous);
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      name: userData?.name,
      email: userData?.email,
      companyName: companyData?.name,
      segment: '',
      state: '',
      city: '',
      contact: '',
      companyEmail: '',
    },
  });

  useEffect(() => {
    reset({ companyName: companyData?.name });
  }, [companyData?.name]);

  const onSubmit = useCallback(async data => {
    setLoading(true);
    await changeUserData(data);

    handleChangeEditing();
    setLoading(false);
  }, []);

  const renderChangeDataButton = useCallback(
    () =>
      isEditing ? (
        <S.ButtonWrapper>
          <div>
            <Button
              text="Cancelar"
              variant="outlined"
              type="button"
              onClick={handleChangeEditing}
              style={{
                borderColor: colors.gray,
                color: colors.gray,
                marginRight: 20,
                width: '16rem',
              }}
            />
            <Button text="Salvar alterações" loading={loading} fullWidth />
          </div>
        </S.ButtonWrapper>
      ) : (
        <S.ButtonWrapper>
          <div>
            <Button
              text="Alterar dados"
              variant="outlined"
              type="button"
              onClick={handleChangeEditing}
              fullWidth
            />
          </div>
        </S.ButtonWrapper>
      ),
    [isEditing, loading],
  );

  return (
    <S.Wrapper onSubmit={handleSubmit(onSubmit)}>
      <S.Session>
        <TabStyles.SessionTitle>Dados pessoais</TabStyles.SessionTitle>
        <S.InputWrapper>
          <InputText
            disabled={!isEditing}
            control={control}
            error={errors?.name?.message}
            name="name"
            title="Nome"
          />
          <InputText
            disabled={!isEditing}
            control={control}
            error={errors?.name?.message}
            name="email"
            title="E-mail"
          />
        </S.InputWrapper>
      </S.Session>
      <S.Session>
        <TabStyles.SessionTitle>Dados da empresa</TabStyles.SessionTitle>
        <S.InputWrapper>
          <InputText
            disabled
            control={control}
            error={errors?.name?.message}
            name="companyName"
            title="Nome da empresa"
          />
        </S.InputWrapper>
        {/* <InputText
            disabled
            control={control}
            error={errors?.name?.message}
            name="segment"
            title="Segmento"
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <InputText
            disabled
            control={control}
            error={errors?.name?.message}
            name="state"
            title="UF"
          />
          <InputText
            disabled
            control={control}
            error={errors?.name?.message}
            name="city"
            title="Cidade"
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <InputText
            disabled
            control={control}
            error={errors?.name?.message}
            name="contact"
            title="Contato"
          />
          <InputText
            disabled
            control={control}
            error={errors?.name?.message}
            name="companyEmail"
            title="E-mail da empresa"
          />
        </S.InputWrapper> */}
      </S.Session>
      {renderChangeDataButton()}
    </S.Wrapper>
  );
};

export default AccountDetails;
