import React from 'react';
import * as S from './styled';

type TabProps = {
  index: any;
  label: string;
  disabled?: boolean;
};

const Tab = ({ index, label, disabled = false, ...rest }: TabProps) => (
  <S.Tab
    {...rest}
    aria-controls={`simple-tabpanel-${index}`}
    id={`simple-tab-${index}`}
    label={label}
    disabled={disabled}
  />
);

export default Tab;
