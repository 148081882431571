import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.mainBg};
    width: 70px;
    height: 70px;
    padding: 10px;
    margin: 0 20px;
    border-radius: 50%;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: 1px solid ${theme.colors.mainBg};
    box-shadow: 1px 1px 0px 2px rgba(0, 0, 0, 0.5);

    :hover {
      opacity: 0.9;
      transition: all 0.8s ease-in;
    }
  `}
`;
