/* eslint-disable no-unused-vars */
import { useCallback } from 'react';
import { useSnackbar } from '../providers/snackbar';
import useAPI from '../services/useApi';

type UploadAvatarProps = {
  file: any;
  entityId: string;
  apiCall: (args: any) => Promise<any>;
};

export const useUploadAvatar = () => {
  const callAPI = useAPI();
  const { showSnackbar } = useSnackbar();

  const uploadAvatar = useCallback(
    async ({ file, entityId, apiCall }: UploadAvatarProps) => {
      const updateData = new FormData();
      updateData.append('avatar', file);

      const params = {
        bodyParams: updateData,
        id: entityId,
        formData: true,
      };

      const response = await callAPI(apiCall, params);

      if (response) {
        showSnackbar({
          type: 'success',
          message: 'Alteração realizada com sucesso!',
        });
        return true;
      }
      showSnackbar({
        type: 'error',
        message: 'Não foi possível realizar a alteração!',
      });
      return false;
    },
    [],
  );

  return uploadAvatar;
};
