import { Button as CustomButton, lighten } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { ButtonStylesProps } from './types/button.type';

export const Button = styled(CustomButton)<ButtonStylesProps>`
  ${({ theme, width, color, rounded }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.primary};
    min-height: 4rem;
    ${width &&
    css`
      width: ${width}px;
    `}

    &.MuiButtonBase-root {
      font-size: 1.3rem;
      ${rounded && 'border-radius: 3rem;'}
    }

    &.MuiButtonBase-root.Mui-disabled {
      background-color: ${lighten(
        color === 'secondary' ? theme.colors.red : theme.colors.primary,
        0.3,
      )};
    }
  `}
`;
