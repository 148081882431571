import { API } from './api';
import { CompanyData } from './company';

export type UserData = {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  company: CompanyData;
  isBoweAdmin: boolean;
};

export const getUserById = async (userId: string) => {
  const api = await API({ auth: true, refresh: true });
  return api.get<UserData>(`/user/${userId}`);
};

export type EditUserProps = {
  bodyParams: FormData | { [key: string]: string };
  id: string;
  formData: boolean;
};

type RequestPasswordProps = {
  email: string;
};

export const patchEditUser = async ({
  bodyParams,
  id,
  formData,
}: EditUserProps) => {
  const api = await API({ auth: true, refresh: true, formData });
  return api.patch(`/user/${id}`, bodyParams);
};

export const postRequestPassword = async (bodyParams: RequestPasswordProps) => {
  const api = await API({ auth: false, refresh: false, formData: false });
  return api.post('/user/reset-password', bodyParams);
};
