import { IconButton } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { ActionButtonProps, SessionProps } from '.';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Session = styled.div<SessionProps>`
  ${({ scroll = false }) =>
    scroll &&
    css`
      height: 50vh;
      overflow-y: auto;
    `}
  margin-top: 1.5rem;
`;

export const Form = styled.form`
  display: flex;
  align-items: flex-end;

  > div {
    flex: 1;
  }
`;

export const GoalItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    border: 1px solid ${theme.colors.lightGray};
    padding: 2rem;
    border-radius: 1rem;
    margin-top: 2rem;
  `}
`;

export const ActionButton = styled(IconButton)<ActionButtonProps>`
  ${({ bgColor }) => css`
    &.MuiButtonBase-root {
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 5px;
      background-color: ${bgColor};
      margin-left: 1rem;
    }
  `}
`;

export const GoalInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
`;
