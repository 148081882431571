import { API } from './api';

type companyParams = {
  companyId: string;
};

export type CompanyData = {
  id: string;
  name: string;
  avatarUrl: string;
  active: boolean;
};

export const getCompanyById = async ({ companyId }: companyParams) => {
  const api = await API({ auth: true, refresh: true });

  return api.get<CompanyData>(`company/${companyId}`);
};
