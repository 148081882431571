import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import React from 'react';
import { Controller } from 'react-hook-form';
import * as S from '../styled';
import { CheckboxProps } from '../types/checkbox.type';

const DatePicker = ({
  name,
  control,
  label,
  error,
  title,
  disablePast = false,
  disableFuture = false,
}: CheckboxProps) => {
  const renderDatePicker = () =>
    control ? (
      <Controller
        name={name}
        control={control}
        defaultValue={new Date().toISOString()}
        render={({ field: { ref, ...field } }) => (
          <KeyboardDatePicker
            {...field}
            autoOk
            variant="inline"
            inputVariant="outlined"
            label={label}
            format="dd/MM/yyyy"
            disablePast={disablePast}
            disableFuture={disableFuture}
          />
        )}
      />
    ) : (
      <KeyboardDatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        onChange={() => null}
        value={new Date().toISOString()}
        label={label}
        format="MM/dd/yyyy"
      />
    );

  return (
    <S.InputWrapper title={title}>
      {title && <S.Title>{title}</S.Title>}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {renderDatePicker()}
      </MuiPickersUtilsProvider>
      {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
    </S.InputWrapper>
  );
};

export default DatePicker;
