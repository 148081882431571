import { TextField } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import * as S from '../styled';
import { TextProps } from '../types/text.type';

const Text = ({
  name,
  control,
  label,
  error,
  title,
  disabled,
  style,
}: TextProps) => (
  <S.InputWrapper title={title} style={style}>
    {title && <S.Title>{title}</S.Title>}
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          disabled={disabled}
          id="outlined-required"
          variant="outlined"
          label={label}
          fullWidth
          {...field}
        />
      )}
    />
    {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
  </S.InputWrapper>
);

export default Text;
