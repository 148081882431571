import {
  Button as ButtonMaterial,
  Card as CardMaterial,
} from '@material-ui/core';
import styled, { css } from 'styled-components';
import { ButtonProps, RoundedImageProps, Disabled } from '.';

export const Wrapper = styled(CardMaterial)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  height: 8rem;
  padding: 0 4rem;
  background-color: ${({ theme }) => theme.colors.white};

  > div {
    display: flex;
    align-items: center;

    > a {
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const roundedImageModifiers = {
  small: () => css`
    width: 3.8rem;
    height: 3.8rem;
  `,
  medium: () => css`
    width: 4.8rem;
    height: 4.8rem;
  `,
  ml: () => css`
    margin-left: 1.6rem;
  `,
  mr: () => css`
    margin-right: 1.6rem;
  `,
};

export const RoundedImage = styled.img<RoundedImageProps>`
  ${({ size, ml, mr }) => css`
    border-radius: 50%;

    ${ml && roundedImageModifiers.ml}
    ${mr && roundedImageModifiers.mr}
    ${!!size && roundedImageModifiers[size]}
  `}
`;

export const Pipe = styled.div`
  width: 0.1rem;
  height: 3.2rem;
  margin: 0 2.4rem;
  background-color: ${({ theme }) => theme.colors.gray};
`;

export const Button = styled(ButtonMaterial)<ButtonProps>`
  ${({ theme, fixedSize }) => css`
    height: 4rem;

    ${fixedSize &&
    css`
      width: 18rem;
    `}

    .MuiButton-label {
      font-size: ${theme.font.sizes.xsmall};
    }
  `}
`;

export const Text = styled.p`
  ${({ theme, color }) => css`
    color: ${color || theme.colors.gray};
  `}
`;

export const Link = styled.a`
  width: auto;
  text-decoration: none;
`;

const gearIconModifier = css`
  filter: brightness(0%);
`;

export const GearIcon = styled.img<Disabled>`
  ${({ disabled }) =>
    css`
      ${!disabled && gearIconModifier};
    `}
  margin-right: 1rem;
`;

export const GearText = styled.p<Disabled>`
  ${({ theme, disabled }) => css`
    color: ${disabled ? theme.colors.primary : theme.colors.black};
  `}
`;
