import styled, { css } from 'styled-components';
import { GenericInputProps } from './types/base.type';

export const InputWrapper = styled.div<Pick<GenericInputProps, 'title'>>`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: ${theme.colors.transparent};
    margin: 0.5rem;
  `}
`;

export const DefaultFontStyle = css`
  margin: 10px 0;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    ${DefaultFontStyle};
    font-weight: ${theme.font.bold};
    color: ${theme.alert.error};
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    ${DefaultFontStyle};
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.black};
  `}
`;
